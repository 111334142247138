.teaser-tiles-list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 -10px;

    list-style: none;
}

.content .teaser-tiles-list {
    margin-bottom: 0;
}

.teaser-tile {
    width: 50%;
    margin: 0;
    padding: 10px;
}

.teaser-tile--full {
    width: 100%;
}

.teaser-tile button,
.teaser-tile a,
.teaser-tile a:link,
.teaser-tile a:visited {
    display: block;

    opacity: 1;

    text-decoration: none;
    position: relative;

    .teaser-tiles--zoom & {
        transition: opacity 0.25s ease;
    }
}
.teaser-tile button:active,
.teaser-tile button:focus,
.teaser-tile button:hover,
.teaser-tile a:active,
.teaser-tile a:focus,
.teaser-tile a:hover {
    img {
        opacity: 0.7;
    }

    .teaser-tiles--zoom & {
        img {
            opacity: 0.9;

            transform: scale(1.05);
            transition: opacity 0.25s ease, transform 0.5s ease;
        }
    }
}

.teaser-tiles--zoom img {
    transition: transform 0.5s ease;
}

.teaser-tile-box {
    opacity: inherit;
}

.teaser-tiles-list--2 .teaser-tile {
    width: 50%;
}

.teaser-tiles-list--3 .teaser-tile {
    width: 33.33%;
}

@media @medium-devices-and-smaller {
    .teaser-tiles-list--3 .teaser-tile {
        width: 50%;
    }
}

@media @small-medium-devices {
    .teaser-tile,
    .teaser-tiles-list--2 .teaser-tile,
    .teaser-tiles-list--3 .teaser-tile {
        width: 100%;
    }
}

.teaser-tile-box {
    position: relative;

    margin: 0;
    padding: 0;
    overflow: hidden;
}

.teaser-tile-box img {
    width: 100%;
    height: auto;
}

.teaser-tile .teaser-tile-box__title {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    height: 50px;
    margin: 0;
    padding: 2px 16px;
    overflow: hidden;

    background-color: rgba(0, 0, 0, 0.7);

    color: #FFFFFF;
    font-size: 24px;
    font-weight: 700;
    line-height: 50px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.teaser-tile--full {

    .teaser-tile-box {
        @media @small-devices {
            min-height: 275px;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                width: auto;
                height: 100%;
            }
        }

        @media @mini-small-devices {
            min-height: 175px;
        }
    }

    .teaser-tile-box__title {
        font-size: 34px;
        line-height: 45px;

        @media @small-devices {
            min-height: 1px;
            font-size: 24px;
        }

        @media @mini-small-devices {
            font-size: 20px;
        }
    }
}

.teaser-tile p {
    height: 50px;
    margin: 0;
    padding: 0 16px;
    overflow: hidden;

    background-color: #FFFFFF;

    color: #666666;
    line-height: 3.125rem;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.teaser-tile--full p {
    height: auto;
    padding: 16px;

    line-height: 1.5;
}

.teaser-tiles-list .mgnlEditorBar,
.teaser-tiles-list .mgnlEditor {
    width: 100% !important;
}

// ".reisebuero-teaser" Kann später entfernt werden, kommt jeweils dreimal in Reisebüro und Italia vor

.reisebuero-teaser div{
     font-size: 0;
}

     .content .reisebuero-teaser ul{
    
    margin-right: -10px;
    margin-left: -10px;
    }
   .content .reisebuero-teaser  li::before{
       
        display:none;
    }
.reisebuero-teaser a {
    display: inline-block;
    position: relative;
    width: 100%;
    height: auto;
    text-decoration: none;
    font-size: 0;
}

.reisebuero-teaser a:active,
.reisebuero-teaser a:focus,
.reisebuero-teaser a:hover {
    opacity: 0.7;
}

.reisebuero-teaser li {
margin-bottom:20px;
padding-left: 10px;
padding-right:10px;

}
.reisebuero-teaser li{

  width: 50%;
   float: left;
}
@media @medium-devices-and-smaller  {
.reisebuero-teaser li{

  width: 50%;
   float: left;
}
}
@media @small-medium-devices  {
  .reisebuero-teaser li{

  width: 100%;
   float: none;
}
}

.reisebuero-teaser ul{
    margin:0;
    padding:0;
    list-style-type: none;
}
.reisebuero-teaser a>.teaser-slider-item__heading {
    position: absolute;
    color: #fff;
    bottom:0;
    left: 0;
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: rgba(0, 0, 0, 0.7);
    z-index: 2;
    padding: 2px 16px 0 16px;
    margin: 0;
    text-align: left;
    font-weight: 700;
    font-size: 20px;
}

.reisebuero-teaser>ul>li>a>div {
    position: relative;
    height: 50px;
    line-height: 50px;
    background: white;
    font-size: 14px;
    color: @color-available;
    padding-left: 58px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.reisebuero-teaser>ul>li>a>.teaser-slider-list-item-info {
    padding: 0 16px;
    color: #000;
}

.reisebuero-teaser>ul>li>a>.teaser-slider-item__heading {
    bottom: 0;
}

.reisebuero-teaser>ul>li.with-item-info>a>.teaser-slider-item__heading {
    bottom: 50px;
}

.teaser-tile .price {
    position: absolute;
    width: 120px;
    height: 60px;
    background: @tint;
    color: white;
    font-size: 18px;
    line-height: 24px;
    bottom: 10px;
    right: 10px;
    padding: 6px;
    text-align: center;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    z-index: 3;
    white-space: pre-wrap;
    overflow: auto;
    text-overflow: ellipsis;
}
