.clearfix:before,
.clearfix:after,
.row:before,
.row:after
{
  display: table;
  content: " ";
}
.container:after,
.clearfix:after,
.row:after{
  clear: both;
}


.container {
  // padding-right: 15px;
  // padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.row--no-margin {
  margin: 0;
}

.row--flex {
  display: flex;
  flex-wrap: wrap;

  &-bottom {
    align-items: flex-end;
  }

  &-center {
    align-items: center;
  }
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  float: left;

  .row--flex & {
    float: none;
  }
}

.col-12 {
  width: 100%;
}
.col-11 {
  width: 91.66666667%;
}
.col-10 {
  width: 83.33333333%;
}
.col-9 {
  width: 75%;
}
.col-8 {
  width: 66.66666667%;
}
.col-7 {
  width: 58.33333333%;
}
.col-6 {
  width: 50%;
}
.col-5 {
  width: 41.66666667%;
}
.col-4 {
  width: 33.33333333%;
}
.col-3 {
  width: 25%;
}
.col-2 {
  width: 16.66666667%;
}
.col-1 {
  width: 8.33333333%;
}

.col-8 ~ .col-4 > :first-child,
.edit-mode .col-8 ~ .col-4 > :nth-child(3) {
  margin-top: 56px;
}

.col-8--without-heading ~ .col-4 > :first-child,
.row--equalized-heading .col-8 ~ .col-4 > :first-child,
.edit-mode .col-8--without-heading ~ .col-4 > :nth-child(3) {
  margin-top: 0;
}

@media @medium-devices-and-smaller  {
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {

  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
   float: none;
}
}
