html.no-scroll {
	overflow: hidden;

	.loyalty-program {
		z-index: auto;
	}
}

.lp-main-screen {
	.oebb-toaster {
		bottom: 105px;
	}
}

ul.service-nav {
	& .loyalty-program-link:not(.ticketshop-link) a {
		min-width: 2.75em;
		max-width: 11.5em;
		margin-right: 3px;
		margin-left: 3px;
		padding-right: 10px;

		background: @tint-lp;

		color: #fff;
		text-align: left;

		&:focus,
		&:hover {
			color: @tint-lp;
			background: #fff;
			text-decoration: none;
		}

		&:active {
			color: #222;
			background: #fff;
			border-color: #222;
		}

		header.is-sticky & {
			padding-right: 10px;

			background-color: @tint-lp;
			&:focus,
			&:hover {
				background-color:white;
			}
		}
	}
}

.content div.image-title-teaser--loyalty-program div.hero-teaser-text {
	.lp--register-btn{
		text-shadow: 0 0 22px #000;
		display: block;
		color: white;
		font-family: "FrutigerNextRegular", Arial, Helvetica, sans-serif;
		margin-top: 1rem;
	}
	@media @small-medium-devices {
		loyalty-program-login-button, loyalty-program-register-button{
			display:none;
		}
	}

}

.loyalty-program {
	position: relative;
	z-index: 10;

	& .teaser-slider {
		padding-top: 0;
		overflow: visible;
	}
}

.lp-greeting {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 2;

	padding: 8px 40px;

	background-color: rgba(0, 0, 0, 0.5);

	color: #ffffff;
	font-size: 18px;
	line-height: 22px;
	display: flex;
	justify-content: space-between;

	@media @big-devices-and-smaller {
		loyalty-program-username {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}

		padding: 12px 15px;
	}
}

.content .lp-steps {
	&__expandable--intro {
		li.expandable {
			a.expandable__header {
				color: @tint-oebb;

				.ic-nav-expand {
					background-image: url("../images/icons/spritemap-oebb.svg#arrow_down-view");
				}
			}

			&.expandable-open {
				a.expandable__header .ic-nav-expand {
					background-image: url("../images/icons/spritemap-oebb.svg#arrow_up-view");
				}
			}

			border-bottom:none;
		}
	}

	.lp-steps-panel {
		--color-scrollbar-face: #999999;
		--color-scrollbar-track: #dddddd;

		display: flex;
		flex-wrap: nowrap;
		flex-direction: row;
		overflow-x: auto;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;

		scrollbar-width: thin;
		scrollbar-color: var(--color-scrollbar-face) var(--color-scrollbar-track);

		&::-webkit-scrollbar {
			height: 14px;
		}

		&::-webkit-scrollbar-track {
			background: var(--color-scrollbar-track);
		}

		&::-webkit-scrollbar-thumb {
			background-color: var(--color-scrollbar-face);
			border-radius: 6px;
			border: 3px solid var(--color-scrollbar-track);
		}

		& .lp-step {
			flex: 1 0 auto;
			width: ~'calc(33% - 20px)';
			min-width: 265px;
			margin-right: 20px;
			position: relative;

			&:last-child {
				margin-right: 0;

				.lp-step__box::after {
					content: none;
				}
			}

			&__box {
				position: relative;

				display: flex;
				flex-direction: column;
				justify-content: space-between;
				min-height: 340px;
				margin-bottom: 18px;
				padding: 30px;

				background-color: #e6e6e6;

				&::after {
					position: absolute;
					top: 42%;
					right: -65px;
					z-index: 1;

					content: '';
					display: block;
					width: 40%;
					max-width: 130px;
					height: 63px;

					background-image: url('../images/loyalty-program/icon-layout-arrow.svg');
					background-position: center;
					background-repeat: no-repeat;
					background-size: 100%;
					transform: translateY(-50%);
				}
			}

			&__image {
				float: right;

				display: block;
				width: 33.33%;
				max-width: 100px;
				margin-left: 10px;
				margin-bottom: 10px;
			}

			&__title {
				margin: 0;
				overflow: hidden;

				font-size: 18px;
				font-weight: normal;
				line-height: 22px;
			}

			&__text {
				// max-width: 10ch;
				margin: 0;

				font-family: @font-bold;
				font-size: 24px;
				line-height: 32px;
			}

			&__description {
				margin: 0;

				ul li {
					margin-bottom: 6px;
					padding-left: 26px;
					position: relative;
				}

				ul li::before {
					background: url("../images/icons/spritemap-lightgreen.svg#hook-view") no-repeat 0;
				}

				a {
					&:extend(.content .row a);
				}

				ul li::before {
					content: " ";
					height: 20px;
					left: 2px;
					position: absolute;
					top: -2px;
					width: 20px;
				}
			}
		}

		&.expandable--closed {
			max-height: 32rem;
			position: relative;

			.lp-step::after {
				position: absolute;
				bottom: 0;
				height: 100%;
				width: 100%;
				content: "";
				background: linear-gradient(0deg, #fff, hsla(0, 0%, 100%, 0) 20%);
				pointer-events: none;
			}
		}
	}
}

.expandable-opacity__toggler {
	margin: 0 auto;
	text-align: center;
	font-family: @font-bold;
	display: block;

	&:hover,
	&:active,
	&:focus {
		.ic-default {
			background-image: url("../images/icons/spritemap-darkgray.svg#arrow_down-view");
		}
	}
}


@media @screen-sm-max {
	.content .voucher-view h2 {
		font-size: 26px;
	}
}

[slot$="-advantages"] ul {
	margin-bottom: 0;

	.content ul.link-list[slot="anchors"] & {
		margin-bottom: 20px;
	}

	&> :first-child {
		margin-top: 0;
	}

	li {
		margin-bottom: 6px;
		padding-left: 26px;
		position: relative;
	}

	li::before {
		background: url("../images/icons/spritemap-lp.svg#hook-view") no-repeat 0;
		content: " ";
		height: 20px;
		left: 2px;
		position: absolute;
		top: -2px;
		width: 20px;
	}
}

.content ul.link-list[slot="anchors"] {
	margin-bottom: 0;
}

[slot="anchors"] li.link-list__item.link-list__item--external a {
	text-decoration: underline;
}

loyalty-program-main-screen>div[slot] {
	display: none;
}

.ic-external-link-thin {
	background-image: url(../images/loyalty-program/linkextern-thin.svg);
	background-size: 20px 20px;
}
.ic-copy {
	background-image: url(../images/loyalty-program/copy.svg);
}
.ic--lp {
	filter: invert(14%) sepia(83%) saturate(3624%) hue-rotate(341deg) brightness(79%) contrast(100%);
}