.external-nav-area {
  padding: 10px;
  text-align: right;
}

ul.external-nav li a {
  display: inline-block;
  width: auto;
  height: 42px;
  padding: 0 5px;
  margin: 10px 0 10px 0;
  border: 1px solid transparent;
}

ul.external-nav li.border-right a {
  border-right: 1px solid #AFAFAF;
}

ul.external-nav li img {
  display: block;
}

ul.external-nav {
  float: left;
}

ul.external-nav.right {
  float: right;
}

.external-nav-area::after {
  content: "";
  display: block;
  clear: both;
}

@media @medium-devices-and-smaller {
  .external-nav-area {
    text-align: center;
  }

  ul.external-nav li.border-right a {
    border-color: transparent;
  }

  ul.external-nav, ul.external-nav.right {
    float: unset;
  }
}

ul.external-nav a:focus,
ul.external-nav a:hover,
ul.external-nav li.border-right a:focus,
ul.external-nav li.border-right a:hover {

  border-color: #878787;
}

ul.external-nav a:active {
  border-color: #fff;
}