.calendar-panel {
    background: #fff;
    padding: 0;
    box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.5);
    position: relative;
}
 .calendar-panel:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    transform: rotate(225deg);
    top: -10px;
    left: 10%;
    margin-left: -13px;
    z-index: 3;
    background:white;
    box-shadow: -1px -1px 10px -2px rgba(0,0,0,.5);
}

.calendar-panel>div{
    position: relative;
     font-size: 18px;
     font-weight: bold;
     -webkit-font-smoothing: antialiased;
     background:white;
      z-index: 4;
      padding:20px;
}

.calendar-panel div.calendar h3 {
    text-align: center;
    padding: 12px 6px 12px 6px;
    font-size: 24px;
}
.calendar-panel div.calendar {
    background: #f5f5f5;
    padding:0 20px 20px 20px;
}

.calendar-panel button.next-btn,
.calendar-panel button.previous-btn,
.calendar-panel button.close-btn
{
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 6px;
    outline: 0;
    transition: color 0.5s ease;
    position: absolute;
    display: inline-block;
}
.calendar-panel button.next-btn {
    top:7px;
    right:0;
}
.calendar-panel>div{
 
    font-size: 20px;

}

.calendar-panel button.previous-btn {
    top: 7px;
    left:0;
}
.calendar-panel button.close-btn {
 top: 11px;
    right: 10px;
  
    opacity: 0.5;
}
.calendar-panel button.close-btn:hover {
   
    opacity: 1.0;
}

.calendar-panel button.next-btn:hover,
.calendar-panel button.previous-btn:hover {
    color: @tint;
}

.calendar-panel table th {
    text-align: center;
    min-width: 20px;
    border: 0;
    margin: 0;
}

.calendar-panel table td {
    text-align: center;
    border: 0;
    margin: 0;
    padding: 0;
}

.calendar-panel table th {
    background: #d7d7d7;
}

.calendar-panel table td button {
    background: transparent;
    border: none;
    width: 100%;
    cursor: pointer;
    padding: 8px;
    margin: 0;
    outline: 0;
    transition: background 0.5s ease, color 0.5s ease;
}

.calendar-panel table tr>td:last-child button {
    color: @tint;
}

.calendar-panel table td button:focus,
.calendar-panel table td button:hover {
    background: #666;
    color: white!important;
}

.calendar-panel table td button.active:hover,
.calendar-panel table td button.active,
.calendar-panel table td button:active {
    background: @tint;
    color: white!important;
}

.calendar-panel table td {
    background: #f5f5f5;
}

.calendar-panel table td.passive {
    background: #f0f0f0;
    opacity: 0.5;
}

.calendar-panel table {
    margin: 0;
    padding: 0;
    border-collapse: collapse;
}