.chat-scrollbar {
	scrollbar-width: thin;
	scrollbar-color: #666666 transparent;

	& { // IE
		scrollbar-face-color: #666666;
		scrollbar-track-color: transparent;
	}

	&::-webkit-scrollbar {
		width: 11px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #666666;
		border-radius: 6px;
		border: 3px solid transparent;
	}
}

.chat-wc-frame {
	position: fixed;
	right: 20px;
	bottom: 30px;
	z-index: 30;

	width: 0;
	height: 0;
	overflow: hidden;

	background: linear-gradient(0deg, rgb(242, 242, 242) 0%, rgb(255, 255, 255) 100%);
	border-radius: 10px;
	box-shadow:  0 3px 7px rgba(0, 0, 0, 0.35);
	opacity: 0;
	visibility: hidden;
	transform: translateY(20px);
	transition: opacity 0.5s ease, visibility 0.5s ease, transform 0.5s ease;

	@media screen and (max-height: 600px) {
		height: 85vh;
	}

	@media @mini-small-devices {
		top: 0;
		right: auto;
		bottom: auto;
		left: 0;

		width: 100%;
		height: 100%;
	}

	a,
	a:link,
	a:visited,
	a:focus,
	a:hover,
	a:active {
		color: @color-oebb-red;
		text-decoration: underline;
	}
}

.chat-wc-frame--open {
	width: 360px;
	max-width: ~'calc(100% - 40px)';
	height: 650px;
	max-height: ~'calc(100% - 60px)';

	@media screen and (max-height: 700px) {
		height: 85vh;
	}

	@media @mini-small-devices {
		width: 100% !important; // Override size change from drag and drop
		max-width: none;
	}
}

.chat-wc-offline-message {
	position: fixed;
	right: 20px;
	bottom: 30px;
	z-index: 20;

	max-width: 400px;
	padding: 12px;
	visibility: hidden;

	background-color: #ffffff;
	opacity: 0;
	transition: all 0.2s ease;

	button {
		float: right;

		margin-bottom: 8px;
		margin-left: 8px;

		background: none;
		border: none;
		cursor: pointer;
	}

	p:last-of-type {
		margin-bottom: 0;
	}
}

.chat-wc-offline-message--open {
	visibility: visible;

	opacity: 1;
}

.chat-wc-button {
	position: fixed;
	right: 20px;
	bottom: 30px;
	z-index: 10;

	display: block;
	width: auto;
	min-width: 60px;
	height: 60px;
	padding: 0;

	background: transparent;
	border: none;
	cursor: pointer;

	&.chat-wc-disable-mobile {
		display: none;

		@media @screen-sm-min {
			display: block;
		}
	}

	&.chat-wc-button--notification::after {
		position: absolute;
		top: 0;
		right: 0;

		content: attr(data-count) '';
		display: block;
		width: 24px;
		height: 24px;

		background: @color-oebb-red;
		border: 2px solid #ffffff;;
		border-radius: 50%;

		color: #ffffff;
		font-size: 14px;
		font-weight: bold;
		-webkit-font-smoothing: antialiased;
		line-height: 20px;
		text-align: center;
	}

	.nightjet &.chat-wc-button--notification::after {
		background: @color-nightjet;
	}

	.loyalty-program & {
		bottom: 180px;

		@media @screen-sm-min {
			bottom: 120px;
		}
	}

	.loyalty-program--logged-in & {
		bottom: 30px;
	}

	img {
		position: absolute;
		top: 0;
		left: -5px;
		z-index: 1;

		display: block;
		width: auto;
		height: 100%;
		padding: 10px;

		background-color: @color-oebb-red;
		border-radius: 50%;

		transform: rotate(0deg);
		animation-name: pulse-wave;
		animation-duration: 3s;
		animation-iteration-count: var(--chat-button-iteration-count, 3);
	}

	img ~ * {
		display: none;
		width: auto;
		max-width: 300px;
		height: 100%;
		padding: 8px;
		padding-left: 60px;
		margin-bottom: 0;

		color: #444444;
		font-weight: normal;
		-webkit-font-smoothing: antialiased;

		background-color: #ffffff;
		border-radius: 30px;
		box-shadow: 1px 2px 7px rgba(0, 0, 0, 0.35);

		@media @screen-sm-min {
			display: inline-flex;
			align-items: center;
		}
	}

	&[data-text-mobile-fade-out="true"] {
		img {
			right: -5px;
			left: auto;
		}

		img ~ * {
			display: inline-flex;
			align-items: center;
			padding-right: 60px;
			padding-left: 8px;
		}
	}

	&.chat-wc-button--text-mobile-fade-out[data-text-mobile-fade-out="true"] {
		img ~ * {
			animation-name: fade-out;
			animation-duration: 1s;
			animation-fill-mode: forwards;

			white-space: nowrap;

			@media @screen-sm-min {
				animation: none;
			}
		}
	}

	&:hover,
	&:focus {
		img ~ * {
			background: @color-oebb-red;

			color: #ffffff;
		}
	}
}

@keyframes fade-out {
	0% {
		overflow: hidden;

		opacity: 1;
		visibility: visible;
	}

	100% {
		max-width: 0;
		overflow: hidden;

		opacity: 0;
		visibility: hidden;
	}
}

@keyframes pulse-wave {
	5% {
		transform: rotate(0deg);
		box-shadow: 0 0 0 0 rgba(226, 0, 42, 0.8);
	}

	10% {
		transform: rotate(-15deg);
	}

	15% {
		transform: rotate(15deg);
	}

	20% {
		transform: rotate(-15deg);
	}

	25% {
		transform: rotate(15deg);
	}

	30% {
		transform: rotate(-15deg);
	}

	35% {
		transform: rotate(15deg);
	}

	80% {
		box-shadow: 0 0 0 20px rgba(226, 0, 42, 0);
	}

	40%,
	100% {
		transform: rotate(0deg);
	}
}

.chat-wc-frame--fade {
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
	transition: opacity 0.5s ease, visibility 0.5s ease, transform 0.5s ease;
}

.chat-wc-titlebar {
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding-right: 8px;
	padding-left: 8px;

	background-color: @color-oebb-red;

	color: #ffffff;
	font-size: 20px;

	button {
		margin: 0;
		padding: 8px;

		background: none;
		border: none;
		cursor: pointer;

		color: #ffffff;
		font-size: 28px;

		&:hover,
		&:focus {
			background: #ffffff;
			color: @color-oebb-red;

			.nightjet & {
				color: @color-nightjet;
			}

			svg {
				fill: currentColor;
			}
		}
	}
}

.nightjet .chat-wc-titlebar {
	background-color: @color-nightjet;
}

.chat-wc-frame .chat-wc-menu {
	position: absolute;
	left: -150px;
	top: 49px;
	z-index: 1;

	width: 150px;

	background-color: #ffffff;
	transition: left 0.5s ease;

	color: #ffffff;

	&--open {
		left: 0;
	}

	ul {
		margin: 0;
		padding: 0;

		list-style: none;

		li {
			margin: 0;
			padding: 0;

			border-bottom: 1px solid #464646;

			&:last-child {
				border-bottom: none;
			}

			&::before {
				content: none;
			}

			a,
			a:link,
			a:visited {
				display: block;
				padding: 8px 6px 8px 16px;

				background-color: rgb(90, 90, 90);

				color: #ffffff;
				text-decoration: none;
			}

			a:focus,
			a:hover,
			a:active {
				background-color: rgb(0, 0, 0);
			}

			a[target="_blank"] {
				background-image: url('../images/icons/spritemap-lightgray.svg#linkextern-view');
				background-position: right 8px center;
				background-repeat: no-repeat;
			}
		}
	}
}

.chat-wc-content {
	height: ~'calc(100% - 48px)';

	border-top: 1px solid #ffffff;
}

.chat-wc-view {
	.chat-scrollbar();

	height: 100%;

	overflow-y: auto;
}

.chat-wc-view__content {
	margin: 8px;
	padding: 12px 12px 24px;

	background-color: #ffffff;
	border-radius: 10px;

	p {
		margin-bottom: 8px;
	}

	ul {
		margin-bottom: 8px;
		padding-left: 16px;
	}

	img {
		max-width: 100%;
		height: auto;
	}

	button {
		margin-bottom: 12px;
		padding: 8px 12px;

		border: none;
		border-radius: 6px;
		// background-color: rgb(135, 140, 145);
		background-color: rgb(186, 189, 192);
		cursor: pointer;

		&:disabled {
			cursor: not-allowed;
		}
	}
}

.chat-wc-view__title {
	display: block;

	margin-bottom: 16px;
	font-size: 1.15em;
}

.chat-wc-privacy-policy {
	svg {
		display: block;
		margin: 0 auto 8px;
	}

	p:last-of-type {
		margin-bottom: 24px;
	}
}

.chat-wc-about__row {
	display: grid;
	grid-template-columns: 50px 1fr;
	grid-column-gap: 16px;
}

.chat-wc-send-form {
	position: relative;

	display: flex;
	padding: 0 12px 0;

	background: none;
}

.chat-wc-send-box {
	flex: 1 1 auto;
	width: 100%;
	padding: 10px 65px 10px 10px;
	box-sizing: border-box;

	border: none;
	border-radius: 10px;

	&:focus {
		outline: 2px solid @tint;
	}

	&--with-upload {
		padding-left: 40px;
	}
}

.chat-wc-send-box-counter {
	position: absolute;
	top: 12px;
	right: 55px;

	min-width: 50px;

	font-size: 12px;
	text-align: right;
}

.chat-wc-send-button {
	flex: 0 1 auto;

	min-width: 35px;

	background: none;
	border: none;
	cursor: pointer;
}

.chat-wc-tools {
	padding: 6px 12px 6px;

	button {
		background: none;
		border: none;
		cursor: pointer;
	}
}

.chat-wc-resize-corner {
	position: absolute;
	top: 0;

	display: none;
	width: 15px;
	height: 15px;

	background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20100%20100%22%20width%3D%2215px%22%20height%3D%2215px%22%20stroke%3D%22white%22%20stroke-width%3D%2212px%22%3E%3Cline%20x1%3D%220%22%20y1%3D%2233%22%20x2%3D%2233%22%20y2%3D%220%22%2F%3E%3Cline%20x1%3D%220%22%20y1%3D%2266%22%20x2%3D%2266%22%20y2%3D%220%22%20%2F%3E%3Cline%20x1%3D%220%22%20y1%3D%22100%22%20x2%3D%22100%22%20y2%3D%220%22%20%2F%3E%3C%2Fsvg%3E');
	background-repeat: no-repeat;
	cursor: nwse-resize;
	opacity: 0.6;

	@media @screen-md-min {
		display: block;
	}
}

#webchat {
	height: ~'calc(100% - 80px)';
	width: 100%;

	padding: 0 12px 12px;

	&.webchat--no-form {
		height: ~'calc(100% - 42px)';
	}

	p {
		margin: 0;
		margin-bottom: 1rem;
	}

	ul {
		margin-bottom: 1rem;
		padding-left: 1rem;
	}

	li {
		&::before {
			content: none;
		}

		&::marker {
			color: #7b0e07;
		}
	}

	.webchat__bubble__content {
		font-size: 16px;

		a,
		a:link,
		a:visited,
		a:focus,
		a:hover,
		a:active {
			color: #7b0e07;
		}

		& > :last-child,
		& .markdown > :last-child {
			margin-bottom: 0;
		}

		img {
			display: inherit;
			width: inherit;
		}

		ul {
			list-style-type: initial;
		}

		table {
			margin-bottom: 0;

			td {
				border: none;
			}
		}
	}

	.webchat__bubble--from-use .webchat__bubble__content {
		a,
		a:link,
		a:visited,
		a:focus,
		a:hover,
		a:active {
			color: #ffffff;
		}
	}

	.webchat__suggested-actions__flow-box {
		justify-content: flex-end;
	}

	.webchat__suggested-action__button,
	.webchat__suggested-actions__button {
		cursor: pointer;
	}

	.webchat__send-box__main {
		position: absolute;

		display: block;
		width: 0;
		height: 0;
		min-height: 0;

		background: none;

		& > * {
			display: none;
		}

		& .webchat__upload-button {
			position: absolute;
			top: 1em;
			z-index: 1;

			display: none;

			& button {
				cursor: pointer;

				&:focus {
					outline-offset: -4px;
				}

				&:hover,
				&:active {
					background: none;
				}
			}

			& .webchat__icon-button__shade,
			& .webchat__icon-button__keyboard-focus-indicator {
				display: none;
			}
		}

		&--with-upload {
			& .webchat__upload-button {
				display: block;
			}
		}
	}

	.webchat__send-box__main,
	.webchat__send-box__main form {
		border-radius: 10px;
	}

	.webchat__fileContent__fileName,
	.webchat__fileContent__size {
		color: #ffffff;
	}

	.webchat__basic-transcript__scrollable {
		.chat-scrollbar();
	}

	.webchat__basic-transcript__activity {
		margin: -10px 0 0;
		padding-left: 0;
	}

	.react-film__filmstrip__item {
		margin: 0;
		margin-right: 10px;
		padding: 0;

		&:first-child {
			padding-left: 35px;
		}

		&:last-child {
			margin-right: 0;
			// padding-right: 35px;
		}
	}

	.ac-adaptiveCard {
		.ac-textBlock,
		.ac-textRun {
			font-size: 16px;
		}

		label {
			.ac-textBlock,
			.ac-textRun {
				font-size: 14px !important;
				font-weight: bold !important;
			}
		}

		input,
		select {
			padding: 6px;

			background-color: #ffffff;
			border-radius: 6px;
			border: 1px solid #878c91;

			&:focus {
				outline: none;
			}
		}

		button {
			background-color: @color-oebb-red !important;
			border-color: @color-oebb-red !important;
			border-radius: 6px;
			cursor: pointer;

			color: #ffffff !important;

			&:hover,
			&[aria-pressed="true"] {
				background-color: #ab0020 !important;
				border-color: #ab0020 !important;

				color: #ffffff !important;
			}
		}
	}

	.webchat__keyboard-help header {
		all: revert;
	}
}
