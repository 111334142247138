
.popover {
    background-color: white;
    box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.5);
    color: #666;
    display: none;
    top: 112px;
    left: 12px;
    padding: 0;
    position: absolute;
    width: 372px;
    height: auto;
    z-index: 3;
}
.popover h4{
    font-size: 20px;
    margin-bottom: 10px;
}
.popover p{
    font-size: 14px;
    margin-bottom: 0;
}

.popover>div {
    min-height: 50px;
    height: auto;
    position: relative;
    z-index: 4;
    background: white;
    padding: 10px;
}

.popover:before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background: #999;
    transform: rotate(225deg);
    /* Prefixes... */
    bottom: -10px;
    left: 50%;
    margin-left: -13px;
    z-index: 3;
    background: white;
    box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.5);
}

.popover img {
    width: 124px;
    height: 124px;
    padding: 0 10px 0 0;
    float: left;
}

.popover a {
    position: absolute;
    display: inline-block;
    top: 10px;
    right: 10px;
    width: 18px;
    height: 18px;
    z-index: 5;
    color: #f5f5f5;
}
.popover a:hover {
 color: #666;
}
.popover a [class^='ic'] {
    min-width: 18px;
    width: 18px;
}
