.package-teaser .row {
  margin-right: -10px;
  margin-left: -10px;
}

.package-teaser .row .col-3,
.package-teaser .row .col-5,
.package-teaser .row .col-7,
.package-teaser .row .col-9 {
  padding-right: 10px;
  padding-left: 10px;
}

.package-teaser .row .col-5 ~ .col-7 {
  padding-right: 30px;

}

.content .package-hero-teaser img,
.content .package-teaser img {
  width: 100%;
  height: auto;
}

.package-teaser .package-teaser-item__title {
  margin-bottom: 18px;
}

.package-teaser .package-teaser-item__destination {
  margin-top: 10px;
  color: @tint;
  font-size: 16px;
  margin-bottom: 10px;
}

.package-hero-teaser p {
  font-size: 24px;
}

.package-teaser p.two-lines {
  display: block;
  width: auto;
  position: relative;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  min-height: 2.6em;
  max-height: 2.6em;
  line-height: 1.5;
  padding-right: 20px;
  margin-bottom: 75px;
}

.package-teaser p.two-lines-ellipsis::after {
  content: "...";
  background: white;
  position: absolute;
  right: 10px;
  top: 1.3em;
  width: 70px;
  text-align: center;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 1) 100%);
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 1) 100%);
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 1) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 1) 100%);
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.package-teaser .price {
  color: @tint;
  font-size: 30px;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}

.package-teaser .more-btn,
.package-teaser a.more-btn {
  background: @tint;
  border: 1px solid @tint;
  color: #fff;
  font-size: 20px;
  width: 100%;
  display: block;
  text-align: center;
  padding: 12px 20px 12px 20px;
  margin-bottom: 5px;
}

.package-teaser a.more-btn:active,
.package-teaser a.more-btn:focus,
.package-teaser a.more-btn:hover,
.package-teaser .more-btn:active,
.package-teaser .more-btn:focus,
.package-teaser .more-btn:hover {
  background: #fff;
  border: 1px solid @tint;
  color: @tint;
}

.package-teaser .white {
  position: relative;
}

.content .price-panel {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  margin-top: 10px;
  margin-bottom: 15px;
}


.price-panel .col-9 {
  text-align: right;
}

.price-info {
  font-size: 14px;
}

@media @medium-devices-and-smaller {
  .content .package-teaser .package-teaser-item__destination,
  .content .package-teaser .package-teaser-item__title,
  .content .package-teaser .package-teaser-item__heading {
    padding-left: 20px;
    padding-right: 20px;
  }

  .content .package-teaser p {
    padding-left: 20px;
    padding-right: 20px;

  }

  .content .price-panel {
    position: relative;
    background: #fff;
    left: auto;
    bottom: auto;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 25px;
    padding-left: 15px;
    padding-bottom: 15px;
    padding-right: 15px;

  }

  .package-teaser p.two-lines {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    overflow: visible;
    height: auto;
    max-height: none;
  }

  .package-teaser p.two-lines:after {
    display: none;
  }


  .content .price-panel .col-3 {
    float: left;
    width: 30%;
    padding-right: 0;

  }

  .content .price-panel .col-9 {
    float: left;
    width: 70%;

  }
}

@media @medium-devices-and-smaller {
  .content .price-panel .col-3 {
    width: 40%;
  }

  .content .price-panel .col-9 {
    width: 60%;
  }

  .content .price-panel .col-3.no-price {
    width: 100%;
    padding-left: 0;
  }

  .content .price-panel .col-9.no-price {
    width: 0%;
  }

  .content .price-panel .more-btn,
  .content .price-panel a.more-btn {
    margin-bottom: 0;
  }
}

@media @small-devices {
  .content .price-panel .more-btn
  .content .price-panel a.more-btn {
    padding: 12px 5px 12px 10px;
  }
}

