@keyframes caret {
    50% {
      background-color: transparent;
    }
  }

.typing {
    position: relative;

    &:after {
        content: "";
        display: inline-block;
        height: 1em;
        width: 2px;
        background: currentColor;
        position: relative;
        top: 2px;
        animation: caret 1s steps(1) infinite;
    }

    &--done:after {
        animation: none;
        opacity: 0;
    }
}

