.emergency-news {
    display: block;
}

.emergency-news ul {
    list-style-type: disc;
    list-style-position: inside;
}

.emergency-news .teaser-tile {
    padding: 0;
}
