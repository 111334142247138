.social-nav-panel {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 22px;
    margin-left: 60px;
    gap: 4px;
}

ul.social-nav {
    display: inline-flex;
    margin: 0;
    padding: 0;
    list-style: none;
    gap: 4px;

    a {
        display: block;
        width: 22px;
        height: 22px;

        &:focus, &:hover {
            img {
                color: #fff;
                background: #878787;
            }
        }

        &:active {
            img {
                color: #222;
                background: #F0F0F0;
            }
        }
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
    }
}

@media @medium-devices-and-smaller {
    .social-nav-panel {
        display: flex;
        flex-wrap: wrap;
        margin: 20px 0 0;
        column-gap: 8px;
    }

    ul.social-nav {
        a {
            padding: 8px;
            width: 48px;
            height: 48px;
        }
    }
}
