.btn {
    display: block;
    max-width: 350px;
    margin-bottom: 5px;
    padding: 12px 20px;

    background: #ffffff;
    border: 1px solid #666666;
    cursor: pointer;

    color: #666666;
    font-size: 1em;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    text-align: center;

    &[disabled] {
        .btn--disabled();
    }
}

.btn-hover {
    .btn();

    border-color: #444444;

    color: #444444;
}

.btn-primary {
    .btn();

    background-color: @tint;
    border-color: @tint;

    color: #ffffff;
}

.btn-primary:hover,
.btn-primary-hover {
    .btn-primary();

    background-color: #ffffff;

    color: @tint;
}

.btn-secondary {
    .btn();

    background-color: transparent;
    border-color: @tint;

    color: @tint;
}

.btn-secondary:hover,
.btn-secondary-hover {
    .btn-secondary();

    background-color: #f9f9f9;

    color: @tint;
}

.btn--sm {
    min-width: 100px;
    padding: 6px 10px;

    font-size: 0.75em;
}

.btn--sm:hover {
    .btn--sm();
}

.btn--disabled {
    background-color: #ffffff;
	color: @tint;
	opacity: 0.5;

    cursor: not-allowed;
	pointer-events: none;
}

.btn-close {
    .btn();
    border: none;
    padding: 0;
}
