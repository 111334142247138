.matomoHeatmap {
    nj-search-root {
        display: block !important;
        visibility: visible !important;
        height: 546px;
        background-image: url("../images/heatmap-static/nj-search-root-475.png");
        background-size: 100% 100%;

        @media screen and (min-width: 480px) {
            height: 466px;
            background-image: url("../images/heatmap-static/nj-search-root-790.png");
        }

        @media screen and (min-width: 798px) {
            height: 262px;
            background-image: url("../images/heatmap-static/nj-search-root-1280.png");
        }

        + .loading-indicator {
            display: none;
        }
    }
}
