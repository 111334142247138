.iframe-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, .8);
    z-index: 999999999;
}

.iframe-overlay .close-button {
    display: block;
    position: absolute;
    top: 15px;
    right: 10px;
    color: #000;
    z-index: 2;
}

.iframe-overlay .close-panorama-overlay {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;

    width: 50px;
    height: 50px;

    background: transparent;
    border: none;
    cursor: pointer;
}

.iframe-overlay h3 {
    display: block;
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    color: #000;
    height: 50px;
    z-index: 2;
    background: white;
    line-height: 50px;
    text-align: center;
}

.iframe-overlay h3 span {
    position: absolute;
    left: 5px;
    right: 50px;

    overflow: hidden;

    text-overflow: ellipsis;
    white-space: nowrap;
}

.iframe-overlay h3:after {
    content: '';
    -webkit-box-shadow: -15px 0 10px 0 rgba(0, 0, 0, .4);
    -moz-box-shadow: -15px 0 10px 0 rgba(0, 0, 0, .4);
    box-shadow: -15px 0 10px 0 rgba(0, 0, 0, .4);
    display: block;
    position: absolute;
    top: 50px;
    height: 10px;
    width: 110%;
    left: -5%;
}

.iframe-overlay .iframe-holder {
    display: block;
    position: fixed;
    top: 5%;
    left: 50%;
    margin-top: 0;
    margin-left: -280px;
    height: 90%;
    width: 560px;
    background: white;

    overflow: auto!important;

    .iframe-overlay--youtube& {
        top: 50%;
        left: 50%;
        margin: 0;

        width: 80%;
        max-width: 1200px;
        transform: translate(-50%, -50%);

        iframe {
            margin-top: 50px;
            padding-top: 0;
            height: ~'calc(100% - 110px)';
        }
    }

}

@media @small-devices {
    .iframe-overlay .iframe-holder {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        margin-top: 0;
        margin-left: 0;
        height: 100%;
        width: 100%;
        background: white;
        padding: 0;
        
        .iframe-overlay--youtube& {
            width: 100%;
        }
    }
}

 .iframe-overlay.iframe-overlay-fullscreen  .iframe-holder {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        margin-top: 0;
        margin-left: 0;
        height: 100%;
        width: 100%;
        background: white;
        padding: 0;
        
       
    }

.iframe-overlay iframe,
.iframe-overlay .frame {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
  width: 100%;
    padding-top: 20px;
     padding-bottom: 0;
    background: white;
    overflow:scroll!important;
    
 
   -webkit-overflow-scrolling: touch!important;
}

.iframe-overlay--youtube .disclaimer-active iframe,
.iframe-overlay--youtube .disclaimer-active .frame {
    display: none;
}

.iframe-overlay--youtube .iframe-holder .video__disclaimer { 
    display: none;
}

.iframe-overlay--youtube .iframe-holder.disclaimer-active {
    padding: 65px 20px 0px;
    height: auto;

    .video__disclaimer {
        display: block;
        margin-bottom: 20px;

        a {
            color: @tint;
        }
    }    
}

.iframe-overlay--youtube .iframe-holder:not(.disclaimer-active) {
    .video__disclaimer-btn {
        &:extend(.video-container.video--ready.video--activated button all);
        margin-top: 15px;
        margin-right: 20px;
    }
}

