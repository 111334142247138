.co2 {
    font-size: 32px;
    text-align: center;

    &--right {
        text-align: right;
    }

    &--flipped {
        display: flex;
        flex-direction: column-reverse;
    }

    &--bigger {
        font-size: 48px;
    }
}

/* Remove padding from class `intended-module` if inside a column module */
.row .co2 {
    padding: 0;
}

.co2__intro,
.content .co2__intro {
    margin-bottom: 8px;
}

.co2__counter {
    color: #78A03C;
    font-family: @font-bold;
    font-size: 150%;
}
