@media print {

    /** Base */

    *,
    *:before,
    *:after {
        // background: transparent !important;
        box-shadow: none !important;

        color: #000 !important;
        text-shadow: none !important;
    }

    html,
    body,
    .container {
        background: transparent;
    }

    body {
        padding: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        page-break-after: avoid;
    }

    h3 {
        font-size: 24px !important;
    }

    h4 {
        font-size: 18px !important;
    }

    h5 {
        font-size: 16px !important;
    }

    header {
        position: static;

        height: auto;
        margin-bottom: 40px;
        padding: 0 0 24px;

        border-bottom: 1px solid #999999;
    }

    header.is-sticky {
        position: static;
    }

    header::after {
        content: normal;
        display: none;
    }

    header .logo-link {
        position: static;
        top: auto;

        margin: 0;
        padding: 0;
    }

    header .burger-link,
    header .main-menu,
    header .service-nav {
        display: none;
    }

    header,
    .container {
        max-width: none;
    }

    .skip-links {
        display: none;
    }

    .content {
        padding: 0;
    }

    .row {
        page-break-inside: avoid;
    }

    .indented-module {
        margin-bottom: 20px;
        padding: 0;
    }

    .white {
        background: transparent;
    }

    .pebble-gray {
        background: transparent;
    }

    footer,
    footer .footer-home-link {
        display: none;
    }

    .content .back-link {
        display: none;
    }

    /** Image-Title-Slider */

    .content .image-title-slider,
    .content .image-title-slider.hide {
        position: static;
    }

    .content .image-title-slider .carousel__indicators,
    .content .image-title-slider .carousel__control-forward,
    .content .image-title-slider .carousel__control-backward,
    .content .image-title-slider .carousel__control-play {
        display: none;
    }

    .content .image-title-slider .carousel__item,
    .content .image-title-slider .carousel__list > div {
        display: block;
        margin-bottom: 20px;

        transition: none;
    }

    /** Image-Title-Teaser */

    .content .image-title-teaser,
    .content .image-title-teaser.hide {
        position: static;
        page-break-inside: avoid;

        height: auto;
        margin-bottom: 20px;
    }

    .content .image-title-teaser > img {
        position: static;
        float: none;

        width: 100%;
        height: auto;
        margin: 0 0 1em 0;
        padding: 0;
    }

    .content .image-title-teaser::after,
    .content .image-title-teaser .image-title-teaser-overlay,
    .content .image-title-teaser .night-sky {
        display: none;
    }

    .content .image-title-teaser > div,
    .content .image-title-teaser.right > div {
        position: static;

        width: 100%;
        margin: 0;
        padding: 0;

        transform: none;

        color: #000000 !important;
    }

    .content .image-title-teaser h1,
    .content .image-title-teaser .image-title-teaser__heading {
        font-size: 40px;
    }

    .content .image-title-teaser p {
        font-size: 20px;
    }

    .content .image-title-teaser .more-btn {
        display: none;
    }

    .content .image-title-teaser > div a,
    .content .image-title-teaser > div a:link,
    .content .image-title-teaser > div a:visited {
        color: #000000;
    }

    .content .image-title-teaser--italia::after {
        display: none;
    }

    .content .hero-teaser-text {
        max-width: 100%;
    }

    /** Connections, Station-Connections */

    .connections,
    .station-connections {
        display: block;
    }

    .connections [class^='ic-'],
    .connections [class*=' ic-'] {
        display: none;
    }

    .connections li > a {
        padding-right: 0;
        padding-left: 0;
    }

    .connections li > div > .row > .col-7 > .row > .col-7,
    .connections li > div > .row > .col-7 > .row > .col-5 {
        width: 100%;;
    }

    .connections li > div > .row .more-btn {
        display: none;
    }

    #station-connections-map {
        display: none;
        margin: 0 !important;

        border: 2px solid silver;
    }

    .connectionsList .expandable-open > div {
        height: auto !important;
        padding: 0 0 16px;
    }

    .connectionsList .col-5,
    .connectionsList .col-7 {
        width: 100%;
    }

    // .timetable tr td:nth-child(2)::after,
    // .timetable tr.arz td:first-child::after,
    // .timetable tr.arzline td:first-child::after {
    //     height: 120% !important;
    // }

    .connectionsList .timetable tr:first-child {
        position: static;
    }

    .connectionsList .timetable th {
        padding-left: 3px;
    }

    .connectionsList .timetable th:nth-child(2) {
        padding-left: 12px;
    }

    .connectionsList .timetable td {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .connectionsList .timetable tr .ic-cartrain {
        display: none;
    }

    [name="connectionsForm"] {
        display: none;
    }

    .image-title-slider.hide + .connections #station-connections-map,
    .image-title-teaser.hide + .connections #station-connections-map {
        display: block;
    }

    /** Quick-Links */

    .content .quicklinks > div {
        border: 1px solid #E4E4E4;
    }

    .quicklinks .row {
        margin: 0;
        padding: 0;
    }

    .content .quicklinks .col-4 {
        position: static;

        width: 50%;
        margin: 0;
        padding: 10px;
    }

    .content .quicklinks a {
        position: static;

        margin: 0;
        padding: 0;
    }

    .content .quicklinks-left {
        display: none;
    }

    .content .quicklinks-middle [class^='ic'] {
        display: none;
    }

    .content .quicklinks-middle p {
        padding-left: 0;
    }

    .content .quicklinks-right a {
        display: none;
    }

    .content .quicklinks-price {
        position: static;
    }

    /** Teaser-Slider */

    .teaser-slider {
        position: static;
        page-break-inside: avoid;

        overflow: visible;
    }

    .teaser-slider-panel {
        margin: 0 -10px;
    }

    .teaser-slider-list,
    .teaser-slider-list-wrapper {
        height: auto;
        max-height: none;
        overflow: visible;
    }

    .content ul.teaser-slider-list {
        font-size: inherit;
        white-space: normal;
    }

    .teaser-slider-list::after {
        clear: both;

        content: '';
        display: table;
    }

    .content .teaser-slider-list li {
        float: left;

        display: block;
        width: 33.33% !important;
        margin: 0;
        padding: 10px;
    }

    .content .teaser-slider-list-quote li {
        float: none;

        display: block;
        width: 100% !important;
    }

    .content .quotes-slider .teaser-slider-panel {
        max-width: none;
    }

    .content .teaser-slider-list li .not-possible,
    .content .teaser-slider-list li .possible {
        // top: -20px;

        padding: 2px 16px 0;

        background: transparent;
    }

    .content .teaser-slider-list li i {
        display: none;
    }

    .content .teaser-slider-list-destination li {
        width: 50% !important;
    }

    /** Teaser-Slider Equipment */

    .teaser-slider-equipment {
        height: auto;
        overflow: visible;

        background: none;
    }

    .teaser-slider-equipment::after {
        clear: both;

        content: '';
        display: table;
    }

    .teaser-slider-equipment > img {
        display: none;
    }

    .teaser-slider-equipment .teaser-slider {
        margin: 0;
        padding: 0;
    }

    .teaser-slider-equipment h1 {
        color: #000000;
    }

    /** Train Equipment */

    .train-equipment {
        display: none;
    }

    /** Link List */

    .content .link-list li a {
        padding: 8px !important;

        background-image: none !important;
    }

    .content .link-list__item-filesize {
        display: none;
    }

    /** Lists */

    .content .row ul {
        list-style: disc;
        padding-left: 16px;
    }

    .content .row li {
        display: list-item;
        padding-left: 0;
    }

    .content .row li::before {
        content: normal;
    }

    /** POI-Maps */

    .poi-maps-wrapper {
        page-break-inside: avoid;
    }

    /** Package-Teaser */

    .package-hero-teaser {
        page-break-inside: avoid;
    }

    .package-hero-teaser .more-btn,
    .package-teaser .more-btn,
    .package-hero-teaser a.more-btn,
    .package-teaser a.more-btn {
        display: none;
    }

    // .package-teaser .row {
    //     page-break-inside: avoid;
    // }

    .package-teaser .col-5 {
        float: right;
    }

    .package-teaser h4 {
        margin-top: 0;
    }

    .package-teaser .price-panel {
        position: static;

        width: auto;
    }

    .package-teaser .price-panel .col-9 {
        width: 100%;

        text-align: left;
    }

    .package-teaser .price-panel .col-3 {
        display: none;
    }

    /** Action Button */

    .content .action-btn-container,
    .content a.action-btn {
        display: none;
    }

    /** Video */

    // .videoWrapper {
    //     display: none;
    // }

    /** Teaser-Tiles */

    .teaser-tiles {
        page-break-inside: avoid;
    }

    .teaser-tile .teaser-tile-box__title {
        position: static;

        background: white;
    }

    /** HIM */

    .him {
        display: none;
    }

    /** From-To-Form */

    .him-from-to {
        display: none;
    }

    /** Callout */

    .content .callout__body {
        border-left: 4px solid @tint;
    }

    .content .callout__body::after {
        content: normal;
    }

    /** Expandables */

    .content .expandables ul {
        padding: 0;
        list-style: none;
    }

    .content .expandables .expandable__icon {
        display: none;
    }

    .content .expandables .expandable > div {
        display: block !important;
        height: auto !important;
        opacity: 1 !important;
    }

    .content .expandables .expandable__header {
        padding-left: 0;
    }

    .content .expandables .expandable__title {
        padding-left: 0;
    }

    .content .expandables .expandable__body {
        padding: 12px;
        padding-left: 0;
    }

    /** Sub-Nav */

    .content .sub-nav ul {
        padding: 0;
        list-style: none;
    }

}
