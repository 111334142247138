form {
    background: white;
    display: block;
    width: 100%;
    padding: 20px;
}

fieldset {
    padding: 0;

    border: none;
}

legend {
    padding: 0 0.5em;
}

 form.gray {
    background: #f5f5f5;
    padding: 0;
}

form.pebble-gray {
    background-color: #f7f6f1;
}

.label-red {
    display: inline-block;
    position: relative;
    background: @tint;
    color: white;
    font-size: 16px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    padding: 7px 16px 7px 8px;
    margin-right: 25px;
    min-width: 120px;
}

.label-red:after {
    content: "";
    position: absolute;
    width: 22px;
    height: 22px;
    transform: rotate(45deg);
    /* Prefixes... */
    top: 50%;
    left: 100%;
    margin-top: -11px;
    margin-left: -11px;
    background: @tint;
}

.input-text input[type=text] {
    display: inline-block;
    border-color: transparent;
    background-color: #f5f5f5;
    box-shadow: 0 1px 0 0 #BABDC0;
    padding: 7px 8px 5px 8px;
    line-height: 24px;
    font-size: 16px;
    width: 150px;
    border-radius: 0;
    -webkit-appearance: none;
}
.input-text-white input[type=text]{
      background-color: #fff;
}
.input-text-full{
     width:100%;
}
.input-text-full.input-text-icon input[type=text]
{
   width:100%;
   line-height: 36px;
    font-size: 24px;
    padding: 15px 8px 13px 15px;
}
 

.input-text input[type=text]:focus {
    outline: 0;
    box-shadow: 0 2px 0 0 @tint;
}

.input-text {
    display: inline-block;
    position: relative;
}
.input-text-fullwidth {
    display: block;
  
}
.input-text [type=reset] {
    display: inline-block;
    position: absolute;
    right:15px;
    top:50%;
    margin-top:-10px;
    width:24px;
    height:24px;
    border:0;
     background: url(../images/icons/spritemap-black.svg#close-view) no-repeat left center;
          background-size: 24px 24px;
     opacity: 0.7
}
    .input-text input[type=button]:focus,
     .input-text input[type=button]:hover{
         opacity: 0.8
     }


.input-text+.input-text {
    margin-left: 20px;
}

.input-text-icon label.label-icon {
    background: yellow;
}

.input-text-icon label.label-icon span {
    display: inline-block;
    position: absolute;
    left: -9999999em;
}

.input-text-icon label:before {
    display: inline-block;
    position: absolute;
    top: 9px;
    left: 6px;
    font: normal normal normal 20px oebb-fonticons;
    /*font-size: 40px;*/
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 20px;
    /* width:auto;*/
    height: 20px;
    vertical-align: bottom;
    opacity: 0.3;
}

.input-text-icon input[type=text] {
    padding: 12px 8px 10px 34px;
}

.label-ic-station:before {
    content: "\E643"!important;
}

.label-ic-calendar:before {
    content: "\E625";
}
.label-ic-search:before {
 content: " ";
	width: 22px;
	height: 14px;
	margin: -2px 0 0 4px;
    background: url(../images/icons/spritemap-lightgray.svg#search-view) no-repeat left center;
   
}
.label-ic-map:before {
 content: " ";
	width: 22px;
	height: 14px;
	margin: 5px 0 0 4px;
background: url(../images/icons/spritemap-black.svg#map-view) no-repeat left center;

   
}


.input-text-full .label-ic-search:before{
    width: 30px;
    height: 30px;
    margin: 10px 0 0 10px;
     background-size: 20px 20px;
     opacity: 0.3;
}
    .input-text-icon.input-text-full input[type=text]{
        padding-left: 45px;
          padding-right: 45px;
    }

.input-float-right {
    float: right;
}

.input-radio {
    display: block;
    position: relative;

    &--right {
        float: right;
    }
}

.input-radio label {
    display: inline-block;
    position: relative;
    margin-left: 0;
}

.input-radio input[type=radio] {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: -9999999em;
}

.input-radio input[type=radio]+span {
    display: inline-block;
    padding: 7px 8px 5px 35px;
    line-height: 24px;
    font-size: 16px;
}

.input-radio input[type=radio]+span:before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    margin-top: -10px;
    left: 8px;
    width: 20px;
    height: 20px;
    background-color: #BABDC0;
    border: 5px solid #BABDC0;
    border-radius: 50%;
}

.input-radio input[type=radio]:focus+span,
.input-radio input[type=radio]:hover+span {
    background-color: #f5f5f5;
}

.input-radio input[type=radio]:checked+span:before {
    background: #666;
    background-color: #fff;
    border: 5px solid @tint;
}

.custom-checkbox[type="checkbox"],
.custom-radio[type="radio"] {
    position: absolute;

    width: 0.1px;
    height: 0.1px;
    vertical-align: baseline;

    appearance: none;
    opacity: 0;
}

.custom-checkbox[type="checkbox"] + label,
.custom-radio[type="radio"] + label {
    position: relative;

    display: inline;
    vertical-align: middle;
    margin-left: 1.5em;

    cursor: pointer;
}

.custom-checkbox[type="checkbox"] + label::before,
.custom-checkbox[type="checkbox"]:checked + label::after,
.custom-radio[type="radio"] + label::before,
.custom-radio[type="radio"]:checked + label::after {
    position: absolute;
    top: 50%;
    left: -1.5em;

    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;

    background-color: #cccccc;
    transform: translateY(-50%);

    line-height: 1.1em;
    text-align: center;
}

.custom-checkbox[type="checkbox"]:checked + label::after,
.custom-radio[type="radio"]:checked + label::after {
    background-color: #e2002a;
    background-image: url('data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20width=%228%22%20height=%228%22%20viewBox=%220%200%208%208%22%3E%3Cpath%20xmlns=%22http://www.w3.org/2000/svg%22%20d=%22M6.41%200l-.69.72-2.78%202.78-.81-.78-.72-.72-1.41%201.41.72.72%201.5%201.5.69.72.72-.72%203.5-3.5.72-.72-1.44-1.41z%22%20transform=%22translate(0%201)%22%20fill=%22%23ffffff%22%20/%3E%3C/svg%3E');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 75%;
    border-color: transparent;
    box-shadow: none;
}

.custom-checkbox[type="checkbox"]:focus + label::before,
.custom-radio[type="radio"]:focus + label::before {
    outline: 1px dotted #444444;
    outline-offset: 2px;
}

// .custom-checkbox[type="checkbox"]:disabled + label::before,
// .custom-readio[type="radio"]:disabled + label::before {}

.custom-radio[type="radio"] + label::before,
.custom-radio[type="radio"]:checked + label::after {
    border-radius: 50%;
}

.custom-radio[type="radio"]:checked + label::after {
    background-image: url('data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%22-4%20-4%208%208%22%3E%3Ccircle%20r=%223%22%20fill=%22%23ffffff%22%20/%3E%3C/svg%3E');
    background-size: 50%;
}

.toggle {
	display: inline-block;
	margin-top: 0;

    .toggle__input {
        position: relative;

        display: inline-block;
        width: 75px;
        height: 32px;
        margin: 0;
        overflow: hidden;
        box-sizing: border-box;
        vertical-align: middle;

        background-color: #f5f5f5;
        border: 1px solid #fff;
        border-radius: 16px;
        box-shadow: inset 1px 1px 3px 1px rgba(0,0,0,0.1);
        cursor: pointer;
        transition: all .2s ease;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        -webkit-tap-highlight-color: transparent;

        &:checked {
            background-color: @color-available;
        }

        &:checked::before {
            right: 31px;

            content: attr(data-on);

            color: white;
            font-weight: bold;
            -webkit-font-smoothing: antialiased;
        }

        &:checked::after {
            transform: translatex(45px);
            background-color: white;
        }

        &::before {
            position: absolute;
            top: 50%;
            right: 13px;

            content: attr(data-off);

            transform: translateY(-50%);
            transition: all .2s;

            color: #000000;
            font-size: 11px;
        }

        &::after {
            position: absolute;
            top: 3px;
            left: 2px;

            content: '';
            width: 24px;
            height: 24px;

            background-color: #ffffff;
            border-radius: 50%;
            box-shadow: 1px 1px 3px rgba(0,0,0,0.35);
            transition: all .2s;
        }
    }
}
