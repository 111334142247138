.rail-tours,
.kombitickets {

	header.is-sticky .logo-link {
		top: 90px;
	}

	@media @big-devices-and-smaller {
		header {
			height: 75px;
		}

		header.scrolled::after,
		header::after {
			top: 75px;
		}

		header .logo-link,
		header.is-sticky .logo-link {
			top: 0;
		}

		header .logo-link img {
			max-width: 100px;
		}

		.content {
			padding-top: 75px;
		}
	}

	@media @small-devices {
		header {
			height: 55px;
		}

		header.scrolled::after,
		header::after {
			top: 55px;
		}

		header .logo-link,
		header.is-sticky .logo-link {
			top: 3px;
		}

		header .logo-link img {
			max-width: 75px;
		}

		.content {
			padding-top: 55px;
		}
	}

	@media @screen-sm-min {
		.angebotssuche-link-list-wrapper {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			> .link-list {
				width: ~'calc(100% / 2 - 20px)';
			}
		}
	}

	@media @screen-md-min {
		.angebotssuche-link-list-wrapper {
			> .link-list {
				width: ~'calc(100% / 3 - 20px)';
			}
		}
	}

	.checkit-frame-wrapper {
		width: 100%;
		position: relative;

		.checkit-frame {
			width: 100%;
		}
	}

	.angebotssuche__wizzard {
		display: flex;

		&-item {
			border: 1px solid @tint;
			border-right: none;
			padding: 20px 20px 20px 40px;
			text-transform: uppercase;
			position: relative;
			height: 60px;
			background: @tint;

			a {
				text-decoration: none;
				color: white;
				font-family: @font-regular;
				font-weight: 700;
			}

			&:before {
				content: "";
				display: block;
				position: absolute;
				width: 0;
				height: 0;
				top: 50%;
				right: 0;
				border-style: solid;
				border-width: 30px 0 30px 30px;
				border-color: transparent transparent transparent white;
				transform: translate(100%, -50%);
				z-index: 1;
			}

			&:after {
				content: "";
				display: block;
				position: absolute;
				width: 0;
				height: 0;
				top: 50%;
				right: 0;
				border-style: solid;
				border-width: 29px 0 29px 29px;
				border-color: transparent transparent transparent @tint;
				transform: translate(100%, -50%);
				z-index: 1;
			}

			&--disabled {
				background: #eee;
				border-color: #eee;
				pointer-events: none;

				a {
					color: #000;
				}
				&:before {
					border-color: transparent transparent transparent white;
				}
				
				&:after {
					border-color: transparent transparent transparent #eee;
				}
			}

			&:hover,
			&:focus,
			&--finished {
				background: white;
				border-color: @tint;

				a {
					color: @tint;
				}
				
	
				&:before {
					border-color: transparent transparent transparent @tint;
				}

				&:after {
					border-color: transparent transparent transparent white;
				}
			}

			&:first-child {
				padding: 20px;
			}
			
			
		}
	}

	.hotel-example {
		li.ic-list-map-geo:before,
		li.ic-list-bus:before {
			background-size: 30px auto;
		}
	}
}
