.klimaticket-scope-map {
	& > section {
		display: block;

		@media @screen-sm-min {
			display: none;
		}
	}

	& > .klimaticket-scope-map-container {
		display: none;

		@media @screen-sm-min {
			display: block;
		}
	}
}

.klimaticket-scope-map-container {
	position: relative;
}

.klimaticket-scope-map-popup {
	position: absolute;
	top: 0;
	left: 0;

	display: block;
	width: 100%;
	max-width: 480px;
	overflow: hidden;
	padding: 12px;

	background-color: #ffffff;

	color: #000000;
	font-size: 16px;

	&[hidden] {
		display: none;
	}

	& h2 {
		font-size: 24px;
	}

	& button {
		position: absolute;
		top: 0;
		right: 0;

		display: block;
		width: 40px;
		height: 40px;

		background: none;
		border: none;
		cursor: pointer;

		font-size: 28px;
		color: #444444;
	}

	& > div > *:last-child {
		margin-bottom: 0;
	}
}

.klimaticket-scope-map svg a:focus {
	outline: none;
}

.is-tabbing .klimaticket-scope-map svg a:focus {
	outline: 2px dotted @tint;
    outline-offset: 2px;
    z-index: 10;
}

.klimaticket-scope-map {
	& svg {
		width: 100%;
		height: auto;

		& path {
			fill: #66afd0;
			stroke: white;
			stroke-width: 1;

			/* transition-duration: 0.2s;
			transform-origin: center;
			transform-box:fill-box; */

			&:hover {
				fill: #5a9ab8;
				/* transform: scale(1.15); */
			}

			&.klimaticket-scope-map-active-path {
				fill: #518AA5;
			}
		}
	}
}

.content {
	.klimaticket-scope-map-content {
		ul {
			list-style: disc;
			margin-left: 1rem;
		}
	}
}
