.search-tab-content ul {
    margin-top: 15px;
}

.search-tab-content li p {
    color: #666;
    margin-bottom: 10px;
}

.search-tab-content li p~p {
    color: #666;
    font-size: 14px;
}

.search-tab-content li .search-result-item__title {
    color: @tint;
    font-size: 20px;
    font-weight: normal;
}

.search-tab-content li .search-result-item__title span {
    color: #555555;
    font-size: 12px;
    font-weight: normal;
    border: 1px solid #555555;
    text-transform: uppercase;
    padding: 3px 12px;
    vertical-align: middle;
    letter-spacing: 1px;
    margin-left: 0.5em;
}

.content .search-tab-content li .search-result-item__title strong {
   
     color: @tint;
}
.search-tab-content li a {
    display: block;
    border-left: 4px solid #E4E4E4;
    padding: 0 0 0 16px;
    margin-bottom: 20px;
    text-decoration: none;
}

.search-tab-content li a:focus,
.search-tab-content li a:hover {
    border-left-color: @tint;
    outline: 0;
}

.search-tab-content li a:active {
    border-left-color: #000;
}

a.search-more-link {
    display: inline-block;
    position: relative;
    font-size: 18px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    color: #BABDC0;
    background: white;
    padding: 10px 20px;
    top: -20px
}

a.search-more-link:focus,
a.search-more-link:hover {
    color: @tint;
}

a.search-tab-link {
    display: inline-block;
    padding: 10px 20px;
    font-size: 18px;
    color: #000;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    text-decoration: none;
}
a.search-tab-link:focus,
a.search-tab-link:hover {
    color: @tint;
}

a.search-tab-link-active {
    color: @tint;
    background: white;
}
a.search-tab-link-active:focus,
a.search-tab-link-active:hover
{
      color:#000;
}

@media @mini-small-devices {
    #search-form #search-query {
        padding: 4px 8px 4px 40px;

        font-size: 16px;
        line-height: 32px;
    }

    #search-form .label-ic-search::before {
        width: 20px;
        height: 25px;
        margin: 0 4px;
    }

    #search-form [type="reset"] {
        background-size: 16px 16px;
    }

    #search-query-autocomplete>div:first-child {
        padding: 6px 20px 4px;
        font-size: 14px;
    }

    #search-query-autocomplete .scroll-panel {
        border-top: 5px solid #fff;
    }

    #search-query-autocomplete .scroll-panel ul li:first-child a {
        border-top: none;
    }

    a.search-tab-link {
        padding: 10px 10px;
        font-size: 16px;
    }
}
   