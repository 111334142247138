.val {

    header .logo-link,
    header.scrolled .logo-link {
        width: 120px;
    }

    header .logo-link img {
        max-height: auto;

        @media @small-devices {
            max-height: 32px;
        }
    }

    .content .image-title-teaser--val::before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        height: 100px;

        content: '';
        background-image: url('../images/val-decorator.png');
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 100%;
        z-index: 3;
    }

    .content .image-title-teaser--val-without-decorator::before {
        content: normal;
    }

}
