.sub-nav {
    font-size: 20px;
    margin-bottom: 20px;
}

.sub-nav ul {
    margin: 0;
    padding: 0;

    list-style: none;
}

.content .row .sub-nav ul li::before {
    content: none;
}

.content .row .sub-nav ul li {
    margin: 0;
    padding: 0;
}

.sub-nav a,
.sub-nav a:link,
.sub-nav a:visited {
    display: block;
    padding: 6px 4px 6px 14px;

    border-left: 4px solid transparent;

    color: #000000;
    text-decoration: none;
}

.sub-nav a:focus,
.sub-nav a:hover,
.sub-nav a:active,
.sub-nav a.sub-nav__active-link {
    border-color: @tint;
    color: @tint;
}

.sub-nav ul li:first-child a {
    margin-top: -6px;
}
