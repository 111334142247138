.panorama-collection-section-tab {
    position: relative;

    display: flex;
    padding-bottom: 20px;

    border-right: 3px solid #f5f5f5;
    transition: border-color 0.2s ease-in-out;

    &.panorama-collection-section-tab--active,
    &:hover {
        border-color: @tint;
        transition: border-color 0.2s ease-in-out;

        &::after {
            opacity: 1;
        }
    }

    &::before,
    &::after {
        content: '';
    }

    &::after {
        position: absolute;
        top: 50%;
        right: -9px;

        content: '';
        display: inline-block;

        border-color: transparent;
        border-style: solid;
        border-width: 7px 0 7px 7px;
        border-left: 7px solid @tint;
        opacity: 0;
        transform: translateY(-50%);
        transition: opacity 0.2s ease-in-out;
    }

    p {
        margin: 0;
    }

    .col-3,
    .col-9 {
        position: static;

        a {
            color: currentColor;
            text-decoration: none;

            &::after {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;

                content: '';
            }
        }
    }
}

.panorama-collection-section {
    display: flex;
    flex-direction: column-reverse;

    &[hidden] {
        display: none;
    }
}

.panorama-collection-item {
    position: relative;

    margin-bottom: 8px;
    overflow: hidden;

    cursor: pointer;

    img:first-child {
        filter: blur(2px);
    }

    img.icon-360 {
        position: absolute;
        top: 50%;
        left: 50%;

        width: 75px;
        height: 75px;
        padding: 10px;

        background: rgba(0, 0, 0, 0.75);
        border-radius: 50%;
        transform: translate(-50%, -50%);
        transition: transform 1s ease;
    }

    &:hover img.icon-360 {
        transform: translate(-50%, -50%) rotateY(360deg);
    }
}

.panorama-collection-thumbnail {
    position: relative;

    display: inline-block;
    width: 16.67%;
    margin: 4px;

    border-top: 3px solid transparent;
    transition: border-color 0.2s ease-in-out;

    &:first-child {
        margin-left: 0;
    }

    &.panorama-collection-thumbnail--active,
    &:hover {
        border-color: @tint;
        transition: border-color 0.2s ease-in-out;

        &::after {
            opacity: 1;
        }
    }

    &::after {
        position: absolute;
        top: -9px;
        left: 50%;

        content: '';
        display: inline-block;

        border-color: transparent;
        border-style: solid;
        border-width: 0 7px 7px 7px;
        border-bottom: 7px solid @tint;
        opacity: 0;
        transform: translateX(-50%);
        transition: opacity 0.2s ease-in-out;
    }
}

@media @big-devices-and-smaller {
    .col-6.panorama-collection-left,
    .col-6.panorama-collection-right {
        width: 50%;
        float: left;
    }

    .panorama-collection-left .col-3 {
        width: 25%;
        float: left;
    }

    .panorama-collection-left .col-9 {
        width: 75%;
        float: left;
    }
}

@media @small-medium-devices {
    .col-6.panorama-collection-left,
    .col-6.panorama-collection-right {
        width: 100%;
        float: none;
    }

    .panorama-collection-left .col-3 {
        width: 25%;
        float: left;
    }

    .panorama-collection-left .col-9 {
        width: 75%;
        float: left;
    }

    .panorama-collection-section-tab {
        margin-bottom: 10px;

        border-right: none;
        border-bottom: 3px solid #f5f5f5;

        // &.panorama-collection-section-tab--active,
        // &:hover {
        //     border-width: 7px 0 7px 7px;
        //     border-bottom: 7px solid @tint;
        // }
        &::after {
            top: auto;
            right: auto;
            bottom: -16px;
            left: 50%;

            border-color: transparent;
            border-width: 0 7px 7px 7px;
            border-top: 7px solid @tint;
            transform: translateX(-50%);
        }
    }
}
