.rail-and-drive {

    .footer-gray {
        display: flex;
        justify-content: end;
        align-items: baseline;
    }

    .footer-nav-consent {
        text-align: left;
    }

    @media @medium-devices-and-smaller {
        .footer-gray {
            display: block;
        }

        .footer-nav-consent {
            text-align: center;
        }
    }
}
