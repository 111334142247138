/* Normale Tabelle */
table {
	margin-bottom:20px;
  	margin-top:0;
	width: 100%;
	max-width: 100%;
	min-width: 300px;
	overflow: hidden;
	position: static;
	z-index: 100;
	border: none;
	border-collapse: collapse;
	border-spacing: 0;
}
.vertical-scroll table{
	margin-bottom: 0;
	margin-top: 0;
}
.vertical-scroll {
	overflow: auto;
	margin-bottom:20px;
}
table td {
	padding: 8px;
	border-bottom: 1px solid #E4E4E4;
	background: transparent;
	text-align: left;
    vertical-align: top;
    position:relative;
}
table{
	width:100%;
}

table caption{
	font-family: @font-light;
	color: #6A6A6A;
	font-size: 15px;
	text-align: left;
	padding-bottom: 3px;
}
table th {
	min-width: 80px;
	max-width: 280px;
	padding: 8px;
	background: white;
	border-bottom: 1px solid #afafaf;
	text-align: left;
	vertical-align: top;
	font-weight: bold;
	-webkit-font-smoothing: antialiased;
}

table tbody th {
	border-bottom: 1px solid #e4e4e4;
}

table td.left,
table th.left {
	text-align: left;
}

table td.center,
table th.center {
	text-align: center;
}

table td.right,
table th.right {
	text-align: right;
}

@media @mini-small-devices {
	table {
		display: block;
		width: 100%;
		min-width: 100%;
		max-width: 300px;
		overflow: scroll;
	}
}

