.him-from-to {
    position: relative;

    min-height: 180px;
}

@media @big-devices-and-smaller {
    .him-from-to {
        min-height: 180px;
    }
}

@media @medium-devices-and-smaller {
    .him-from-to {
        min-height: 240px;
    }
}

@media @small-medium-devices {
    .him-from-to {
        min-height: 280px;
    }
}

.him-from-to--from-to-hidden {
    min-height: auto;

    .from-to {
        display: none;
    }

    .him__detail p {
        max-width: none;
    }
}
