.quiz {
    display: none; // Quiz only works with JS

    form {
        padding: 0;
    }

    &__question {
        display: none;
    }

    &__solution {
        display: none;

        p:last-child {
            margin-bottom: 0;
        }
    }

    .edit-mode & {
        &__question,
        &__solution {
            display: block;
            margin-bottom: 1.5em;
        }

        &__solution:last-child {
            margin-bottom: 0;
        }
    }
}

.quiz-question {

    &__text {
        font-weight: bold;
        -webkit-font-smoothing: antialiased;
    }

    &__count {
        display: block;
        margin-bottom: 0.5em;

        color: #555555;
    }

}

