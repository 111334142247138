.contrast {

    .image-title-teaser.left .hero-teaser-text,
    .image-title-teaser.right .hero-teaser-text {
        max-width: 70%;

        padding-top: 16px;
        padding-bottom: 16px;

        background-color: rgba(0, 0, 0, 1);

        @media @small-medium-devices {
            max-width: none;

            padding: 16px 30px;
        }
    }

    .image-title-teaser.left .hero-teaser-text {
        padding-right: 14px;
    }

    .image-title-teaser.right .hero-teaser-text {
        padding-left: 14px;
    }

    &.nightjet .hero-teaser-text {
        background-color: rgba(17, 27, 66, 0.75);
    }

    &.reisebuero .hero-teaser-text {
        background-color: rgba(5, 60, 70, 0.75);
    }

    &.italia .hero-teaser-text {
        background-color: rgba(5, 50, 10, 0.8);
    }

    .content,
    .teaser-tile p,
    .content .row li,
    .search-tab-content li p,
    .content .link-list li a,
    .content .link-list li.link-list__item a {
        color: #000000;
    }

    .indented-module {
        background-color: #ffffff;
    }

    .indented-module.teaser-slider-overlay,
    .indented-module.footer-blue {
        background: #000000;
    }

    .indented-module.teaser-slider-overlay h2,
    .indented-module.teaser-slider-overlay .bigger {
        color: #ffffff;
    }

    .quicklinks,
    #search-page-dynamic,
    #search-tab-link-panel {
        background-color: transparent;
    }

    .teaser-tile-box__title,
    .teaser-slider-list > li > a > .teaser-slider-item__heading {
        background-color: #000000;
    }

    .teaser-tile a,
    .teaser-tile a:link,
    .teaser-slider-three ul > li > a {
        border: 1px solid #444444;
    }

    .teaser-slider-equipment .teaser-slider {
        position: relative;
        background-color: transparent;
    }

    .teaser-slider-equipment .teaser-slider__heading {
        position: relative;
        top: -30px;

        width: ~'calc(100% + 250px)';
        margin-bottom: 8px;
        margin-left: -125px;
        padding: 8px;

        background-color: #000000;

        @media @big-devices-and-smaller {
            width: ~'calc(100% + 40px)';
            margin-left: -20px;
        }
    }

    .train-equipment > img {
        display: none;
    }

    .expandable {
        border-bottom: 1px solid #999999;
    }

    .connections li > a {
        border-bottom: 1px solid #999999;
    }

    .connections li > a ~ div {
        border: 1px solid #cccccc;
        border-top: none;
    }

    .footer-gray,
    .footer-links {
        background-color: #000000;
    }

    ul.footer-nav a:focus, ul.footer-nav a:hover,
    ul.social-nav a:focus, ul.social-nav a:hover,
    .footer-links__block a:hover {
        background-color: #444444;
    }

}
