.autocomplete-panel {
    background: #fff;
    padding: 0;
    box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.5);
    position: relative;
    min-width:350px;
    margin-right:40px;
    z-index: 10;
}

.autocomplete-panel li span {
    float: right;
    vertical-align: middle;

    display: inline-block;
    margin-left: 25px;
    padding: 3px 12px;

    border: 1px solid #555555;

    color: #555555;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
}

@media @small-devices {
    .autocomplete-panel {
  min-width:300px;
   margin-right:50px;
    }
}

@media @mini-devices {
    .autocomplete-panel {
  min-width:250px;
   margin-right:50px;
    }
}
@media @mini-mini-devices{
    .autocomplete-panel {
  min-width:200px;
   margin-right:50px;
    }
}
.autocomplete-panel div{
    position: relative;
     font-size: 18px;
     font-weight: bold;
     -webkit-font-smoothing: antialiased;
     background:white;
     z-index:4;
     padding:0;
}
    .autocomplete-panel>div:first-child{
   
     padding:20px;
}
    .autocomplete-panel:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    transform: rotate(225deg);
    top: -10px;
    left: 10%;
    margin-left: -13px;
    z-index: 3;
    background:white;
    box-shadow: -1px -1px 10px -2px rgba(0,0,0,.5);
}

.autocomplete-panel div.autocomplete h3 {
    text-align: center;
    padding: 12px 6px 12px 6px;
    font-size: 24px;
}
.autocomplete-panel div.autocomplete{
    background: #f5f5f5;
}


.autocomplete-panel button.close-btn
{
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 6px;
    transition: color 0.5s ease;
    position: absolute;
    display: inline-block;
    top: 10px;
    right: 10px;
    opacity: 0.5;
}

.autocomplete-panel>div ul{
    margin-bottom:0;
   
}

.autocomplete-panel button.close-btn:focus,
.autocomplete-panel button.close-btn:hover { 
    opacity: 1.0;
}

.scroll-panel{
    min-height: 40px;
    max-height: 300px;
    overflow: auto;
}
.scroll-panel ul{
   list-style-type: none;
   margin:0;
   padding:0;
}
.scroll-panel ul h4{
  padding: 12px 20px 12px 20px;
    font-size: 14px;
    text-transform: uppercase;
    background-color: #f5f5f5;
    color: #666;
}
.scroll-panel ul a{
    display:block;
  padding: 12px 20px 12px 20px;
    font-size: 20px;
    font-weight: normal;
    color: #000;
    text-decoration: none;
    border-bottom: 1px solid #f5f5f5;
}
.scroll-panel ul li:first-child a{
      border-top: 1px solid #f5f5f5;
}
.scroll-panel ul a:active,.scroll-panel ul a:focus,.scroll-panel ul a:hover{
     color: @tint;
}

.scroll-panel ul .no-suggestions {
    display: block;
    padding: 8px 20px 24px;

    color: #666666;
    font-style: italic;
}

@media @mini-small-devices {
    .autocomplete-panel {
        top: 130px;
        right: 20px;
        left: 20px;

        width: auto;
        margin: 0;
    }

    .autocomplete-panel::after {
        top: -7px;

        width: 15px;
        height: 15px;
    }

    .autocomplete-panel>div:first-child {
        padding: 8px 20px;
        font-size: 16px;
    }

    .autocomplete-panel button.close-btn {
        top: 0;
        padding: 4px;
    }

    .autocomplete-panel button.close-btn .ic-close {
        background-size: 16px 16px;
    }

    .scroll-panel ul:empty::after {
        content: '...';
        display: block;
        padding: 8px 20px;
    }

    .scroll-panel ul h4 {
        padding: 4px 20px;
    }

    .scroll-panel ul a {
        padding: 8px 20px;
        font-size: 16px;
    }

    .autocomplete-panel li span {
        padding: 3px 6px;
        font-size: 10px;
    }

    .scroll-panel ul .no-suggestions {
        padding: 16px 20px 16px;
        font-size: 16px;
    }
}
