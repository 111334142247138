@import "button-mixins";

.postbus-timetables th:nth-child(1),
.postbus-timetables td:nth-child(1) {
    width: 140px;
    overflow: hidden;

    text-overflow: ellipsis;
    white-space: nowrap;
}

.postbus-timetables th:nth-child(2),
.postbus-timetables td:nth-child(2) {
    width: 120px;
}

.postbus-timetables__form input[type="text"] {
	padding: 0.75em 0.5em;

	background-color: #f5f5f5;
	border: none;
	border-radius: 0;
	box-shadow: 0 1px 0 0 #babdc0;
	outline: none;
	-webkit-appearance: none;

	font-size: 1em;

	&:focus {
		box-shadow: 0 2px 0 0 @tint;
	}
}

.postbus-timetables-form__assistive {
    color: #6e6e6e;
}

.postbus-timetables__form input[type="submit"] {
    .btn-primary();
}

.postbus-timetables__form input[type="submit"]:hover {
    .btn-primary-hover();
}

.postbus-timetables__results > div > p {
    text-align: center;
}

.postbus-timetables__results button {
    .btn-secondary();

    margin: 0 auto;
}

.postbus-timetables .postbus-timetables-icon-download {
	fill: #666666;
}

.postbus-timetables__new-badge {
	background-color: #008039;

	color: #ffffff;
}

@media @small-medium-devices {
    .postbus-timetables th:nth-child(2),
    .postbus-timetables td:nth-child(2) {
        width: auto;
    }
}

@media @mini-small-devices {
    .postbus-timetables th:nth-child(1),
    .postbus-timetables td:nth-child(1) {
        width: auto;
        max-width: 95px;
    }

    .postbus-timetables th:nth-child(2),
    .postbus-timetables td:nth-child(2) {
        display: none;
    }
}
