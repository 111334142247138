footer {
    opacity: 1;
}

.full-width-module {
    padding: 0;
}

.footer-blue {
    background: #111b42;
    color: white;
    padding: 30px 20px;
    text-align: center;
}

.footer-gray {
    background: #464646;
    color: white;
    text-align: right;
    position: relative;
    padding: 40px 40px;
}
@media @medium-devices-and-smaller {
    .footer-gray {
    
    text-align: center;
   
    padding: 30px 20px 0;
}
}

.footer-light-gray {
    background: #f5f5f5;
}

.footer-light-gray__heading {
    display: block;
    font-size: 18.75px;
    font-weight: 700;
}

.footer-white {
    background: #ffffff;
    color: #000000;
}

.footer-home-link {
    display: inline-block;
    position: absolute;
    top: 45px;
    left: 40px;
    color: white;
}
.footer-home-link span{
    color: white;
    vertical-align: top;
    margin-left: 18px;
    display: inline-block;
    font-size: 20px;
    font-weight: normal;
    font-family: @font-regular;
    margin-top: -1px;
}
@media @medium-devices-and-smaller {
.footer-light-gray__heading {
   margin-bottom:6px;
}
.footer-light-gray p {
   margin-bottom:15px;
}

    .footer-home-link {
    display: block;
    position: relative;
    width:100%;
    top: auto;
    left: auto;
    text-align: center;
}
}

.newsletter-btn {
    color: white;
    border: 1px solid white;
    padding: 10px 12px 10px 20px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    text-decoration: none;
    transition: background 0.3s, color 0.3s;
    margin-left: 20px;
}

.newsletter-btn:active,
.newsletter-btn:focus,
.newsletter-btn:hover {
    color: #111b42;
    background: white;
}

@media @small-devices {
    .newsletter-btn {
        margin: 20px 0 0 0;
       
        display: block;
        width: 100%
    }
}

.img-filter {
    filter: grayscale(100%);
    transition: filter 0.3s ease;
}

.img-filter:hover {
    filter: grayscale(0%);
}