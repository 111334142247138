.footer-links {
    position: relative;

    display: flex;
    flex-wrap: wrap;

    padding-top: 0;

    background-color: #464646;
}

.footer-links__block {
    flex: 1 1 0px;

    margin: 0 15px;
    box-sizing: border-box;

    color: #ffffff;
}

.footer-links__block--first {
    margin-left: 0;
}

.footer-links__block--last {
    margin-right: 0;
}

.footer-links__block strong {
    display: block;
    margin-bottom: 0.75em;
}

.footer-links__block a {
    display: inline-block;
    padding: 4px 3px;

    color: #FFFFFF;
    text-decoration: none;
}

.footer-links__block a:hover {
    background: #676767;
}

.footer-links .link-list {
    margin: 0;
    padding: 0;

    list-style: none;

    text-align: left;
}

.footer-links .link-list li {
    margin: 0.25em 0;
}

.footer-links .link-list img {
    vertical-align: middle;
    display: inline-block;
    width: auto;
    max-height: 20px;
}

.footer-links .link-list .link-list__item--external .ic-external-link {
    display: none;
}

@media @big-devices-and-smaller {
    .footer-links {
        padding: 30px 20px;
    }
}

@media @medium-devices-and-smaller {
    .footer-links__block {
        flex: 0 1 auto;
        width: 48%;
        margin: 0 2% 14px;

        strong {
            margin-bottom: 0;
        }

        a {
            padding: 11px 3px;
        }
    }

    .footer-links__block:nth-child(odd) {
        margin-left: 0;
    }

    .footer-links__block:nth-child(even) {
        margin-right: 0;
    }

    .footer-links__block--before-last,
    .footer-links__block--last {
        margin-bottom: 0;
    }

    .footer-links .link-list {
        text-align: center;
    }
}

@media @small-devices {
    .footer-links__block {
        float: none;

        width: 100%;
        margin: 0 0 24px 0;
    }

    .footer-links__block--last {
        margin-bottom: 0;
    }
}

/** Magnolia edit mode */

.edit-mode .footer-links > .mgnlEditor {
    width: 100% !important;
}
