.teaser-slider {
    overflow: hidden;
}

.teaser-slider .close-link {
    display: none;
}

.teaser-slider-overlay a.close-link {
    display: block;
}
.teaser-slider * {
    -webkit-user-drag: none;
}

.teaser-slider-list-wrapper {
    max-height: 90%;
    overflow: hidden;
    -webkit-transform: translateZ(0) scale(1.0, 1.0);
    transform: translateZ(0);
    padding: 4px 0;
}
.teaser-slider-position-wrapper{
    position:relative;
}
.teaser-slider-panel {
    position: relative;
}

.teaser-slider-panel .backward-link {
    top: 50%;
    left: -125px;
    height: 60%;
    transform: translateY(-50%);
}

.teaser-slider-panel .forward-link {
    top: 50%;
    right: -125px;
    height: 60%;
    transform: translateY(-50%);
}

.teaser-slider-panel>a {
    display: none;
    position: absolute;
    width: 125px;
    height: 100%;
    color: #111B42;
}

.teaser-slider-panel>a:active,
.teaser-slider-panel>a:focus,
.teaser-slider-panel>a:hover {
    color: @tint;
}

.teaser-slider-panel>a.close-link {
    top: -50px;
    right: -50px;
    left: auto;
    color: white;
    width: 50px;
    height: 50px;
    z-index: 999999;
}

.teaser-slider-overlay .teaser-slider-panel>a {
    color: white;
    opacity: 1.0;
}

.teaser-slider-panel > a > [class^='ic'] {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 40px;
    width: 40px;
    margin-top: -20px;
    margin-left: -20px;
    background-size: 40px 40px;
}

@media @big-devices-and-smaller {
    .teaser-slider-panel>a> [class^='ic'] {
        left:0;
        height: 20px;
        width: 20px;
        margin-top: -10px;
        margin-left: 0;
        background-size: 20px 20px;
    }
 .teaser-slider-one .teaser-slider-panel>a> [class^='ic'] {
      margin-left:0;
      left:0;
      height: 20px;
      width: 20px;
    margin-top: -10px;
    margin-left: -10px;
    background-size: 20px 20px;
    }
    .teaser-slider-panel .backward-link {
        left: -18px;
        margin-right: auto;
        width:20px;
        z-index: 1;
    }
    .teaser-slider-panel a.forward-link {
        right: -18px;

        margin-left: auto;
        width:20px;
        z-index: 1;
    }
}

.content ul.teaser-slider-list {
    width: auto;
    white-space: nowrap;
    margin: 0;
    padding: 0;
    font-size: 0;
    height: auto;
}

.teaser-slider-geolocation-edit-mode {
    position: absolute;
    top: 4px;
    right: 4px;

    display: block;
    padding: 3px 5px;

    background-color: #222222;
    border-radius: 2px;

    font-size: 14px;
    color: #ffffff;
    text-transform: uppercase;
}

.teaser-slider-list>li {
    width: auto;
    height: auto;
    position: relative;
    display: inline-block;
    margin: 0 21px 0 0;
    padding: 0;
    list-style-type: none;
    text-align: center;
   vertical-align: top;
}

.teaser-slider-list>li:last-child {
    margin: 0 0 0 0;
}

.teaser-slider-list>li>a {
    display: inline-block;
    position: relative;
    width: 100%;
    height: auto;
    text-decoration: none;
}

.teaser-slider-list>li>a:active img,
.teaser-slider-list>li>a:focus img,
.teaser-slider-list>li>a:hover img {
    opacity: 0.7;
}

.teaser-slider-one .teaser-slider-list>li>a:active img,
.teaser-slider-one .teaser-slider-list>li>a:focus img,
.teaser-slider-one .teaser-slider-list>li>a:hover img {
    opacity: 1.0;
}

span.img-copyright {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    width: auto;
    padding: 2px 6px;
    background: rgba(0, 0, 0, .5);
    color: white;
    font-size: 13px;
}

span.img-title {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 4px 6px;
    background: rgba(0, 0, 0, .5);
    color: white;
    font-size: 20px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.teaser-slider-one span.img-copyright {
    display: inline-block;
}

.teaser-slider-one span.img-title {
    display: block;
}

.teaser-slider-list>li>a>img {
    vertical-align: middle;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    max-width: 1280px
}
.teaser-slider-list>li>a>.teaser-slider-item__heading {
    position: absolute;
    color: #fff;
    bottom: 50px;
    left: 0;
    width: 100%;
    height: 50px;
    overflow: hidden;
    line-height: 50px;
    background: rgba(0, 0, 0, 0.7);
    z-index: 2;
    padding: 2px 16px 0 16px;
    margin: 0;
    text-align: left;
    text-overflow: ellipsis;
    font-size: 20px;
    font-weight: 700;
}
.teaser-slider-list>li>a>.teaser-slider-item__heading.bottom {
  bottom: 0;
}

.teaser-slider-list>li>a>div {
    position: relative;
    height: 50px;
    line-height: 50px;
    background: white;
    font-size: 14px;
    color: @color-available;
    padding-left: 58px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.teaser-slider-list>li>a>.teaser-slider-list-item-info {
    padding: 0 16px;
    color: #000;
}

.teaser-slider-list>li>a>div.not-possible {
    color: @color-not-available;
}

.teaser-slider-list>li>a>div.partli-possible {
    color: @color-available;
}

.teaser-slider-list>li>a>div .ic-cartrain {
  position: absolute;
    top: 7px;
    left: 7px;
    width: 43px;
    height: 35px;
    background-size: 43px 35px;

}

.teaser-slider-list>li>a>div .ic-attention {
    position: absolute;
    font-size: 18px;
    color: #F89406;
    top: 22px;
    left: 27px;
}

.teaser-slider-list>li>a>div .ic-info {
    position: absolute;
    font-size: 18px;
    color: #BABDC0;
    top: 15px;
    right: 4px;
}

.teaser-slider-list>li>a>.price {
    position: absolute;
    width: 120px;
    height: 60px;
    background: @tint;
    color: white;
    font-size: 18px;
    line-height: 24px;
    bottom: 60px;
    right: 10px;
    padding: 6px;
    text-align: center;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    z-index: 3;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.teaser-slider-list-data>li>a>.price,
.teaser-slider-list-generic>li>a>.price,
.teaser-slider-list-generic-geo>li>a>.price {
    bottom: 10px;

    @media @small-medium-devices {
        bottom: auto;
        top: 10px;
    }
}

.teaser-slider-list-data>li.with-item-info>a>.price,
.teaser-slider-list-generic>li.with-item-info>a>.price,
.teaser-slider-list-generic-geo>li.with-item-info>a>.price {
    bottom: 60px;

    @media @small-medium-devices {
        bottom: auto;
        top: 10px;
    }
}

.teaser-slider-list-data > li > a > .price,
.teaser-slider-list-generic > li > a > .price,
.teaser-slider-list-generic-geo > li > a > .price {
    display: flex;
    justify-content: center;
    align-items: center;
}

.teaser-slider-list-data>li>a>.teaser-slider-item__heading,
.teaser-slider-list-generic>li>a>.teaser-slider-item__heading,
.teaser-slider-list-generic-geo>li>a>.teaser-slider-item__heading {
    bottom: 0;
}

.teaser-slider-list-data>li.with-item-info>a>.teaser-slider-item__heading,
.teaser-slider-list-generic>li.with-item-info>a>.teaser-slider-item__heading,
.teaser-slider-list-generic-geo>li.with-item-info>a>.teaser-slider-item__heading {
    bottom: 50px;
}

.teaser-slider-list-data>li>a:before,
.teaser-slider-list.teaser-slider-list-generic>li>a:before {
    bottom: 0;
}

.teaser-slider-equipment {
    position: relative;
    background: black;
    overflow: hidden;
}

.teaser-slider-equipment > img {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;

    display: block;
    width: auto;
    height: 100%;

    transform: scale(1.2) translate(-50%, -50%);
    transform-origin: left;

    @media @small-medium-devices {
        transform: scale(1) translate(-50%, -50%);
    }
}

.teaser-slider-equipment .teaser-slider {
    position: relative;
    z-index: 2;
    min-height: 350px;
    background: transparent;

    .redesign-2024 & {
        min-height: 520px;
        display: flex;
        justify-content: center;
        align-items: center;

        .teaser-slider__inner {
            width: 100%;
        }
    }
}

.teaser-slider-equipment .teaser-slider__heading {
    font-size: 35px;
    color: white;
    text-align: center;
    text-shadow: 0 0 22px #000000;
    font-weight: 700;
}

.teaser-slider-equipment .teaser-slider__heading small {
    font-size: 80%;
    font-weight: normal;

    @media @small-medium-devices {
        font-size: 0.75em;
    }
}


.teaser-slider-equipment .indented-module {
    padding: 26px 125px;

    @media @big-devices-and-smaller {
            padding: 26px 20px;
    }
}

.teaser-slider-equipment .teaser-slider-panel>a {
    color: white;
}

.content .triangle {
    display: block;
    position: absolute;
    content: "TOP";
    background: transparent url(../images/triangle-red-60x60.svg) top left no-repeat;
    color: white!important;
    top: 0;
    left: 0;
    width: 60px;
    height: 60px;
    line-height: 50px;
    font-size: 16px;
}

.content .triangle:after {
    display: inline-block;
    transform: rotate(-45deg);
    content: "TOP";
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    padding-left: 4px;
    margin-left: -20px;
}


/* Zitate-Slider */

.content ul.teaser-slider-list-quote {
    width: 100%;
}

.quotes-slider .teaser-slider-list>li>a>img {
    display: block;
    position: absolute;
    width: 164px;
    height: 164px;
    border-radius: 50%;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
}

.quotes-slider .teaser-slider-panel {
    max-width: 50%;
    margin: 0 auto;
}

.quotes-slider .teaser-slider-panel .teaser-slider-quote-item__heading small {
    padding-top: 0;
    margin-top: 0;
    display: block;
    font-weight: normal;
    font-size: 1.25rem;
}

.quotes-slider .teaser-slider-panel .teaser-slider-quote-item__heading {
    position: relative;
    top: auto;
    left: auto;
    bottom: auto;
    width: 100%;
    height: auto;
    color: #666;
    padding-left: 0;
    font-size: 1.875rem;
    line-height: 2.25rem;
    background: transparent;
    font-weight: 700;
    text-align: left;
    margin: 0;
}

.quotes-slider .teaser-slider-panel p {
    display: block;
    margin: 0;
    padding: 0;
    font-size: 1rem;
    white-space: normal;
    text-align: left;
}

.quote {
    display: inline-block;
    position: absolute;
    font-family: Arial, Times, serif;
    color: @tint;
    font-size: 150px;
    line-height: 150px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    width: 90px;
    height: 90px;
    top: -20px;
    left: 100px;
}

.quotes-slider .teaser-slider-list>li>a:before {
    display: none;
}

.quotes-slider .teaser-slider-list>li>a {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
    text-decoration: none;
    color: #666;
    min-height: 164px;
    padding-left: 184px;
}

.quotes-slider .ic-nav-forward {
    background-image: url(../images/icons/spritemap-black.svg#arrow_right-view);
}

.quotes-slider a:hover .ic-nav-forward {
    background-image: url(../images/icons/spritemap-oebb.svg#arrow_right-view);
}

.quotes-slider .ic-nav-backward {
    background-image: url(../images/icons/spritemap-black.svg#arrow_left-view);
}

.quotes-slider a:hover .ic-nav-backward {
    background-image: url(../images/icons/spritemap-oebb.svg#arrow_left-view);
}

.teaser-slider.teaser-slider-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    background: black;
    top: 0;
    left: 0;
    color: #ffffff;
}

.gallery-slider.teaser-slider-one .teaser-slider-list>li>a>img {
    width: 100%;
    margin: auto;
}

.gallery-slider.teaser-slider-one .teaser-slider-list>li>a {
    width: calc(~'100% - 200px');
    max-width: 1280px;
    margin: auto;

    @media @medium-devices-and-smaller {
        width: calc(~'100% - 160px');
    }

    @media @small-devices {
        width: calc(~'100% - 60px');
    }
}

.gallery-slider.teaser-slider-one .teaser-slider-panel {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.gallery-slider.teaser-slider-one .teaser-slider-panel .teaser-slider-list-wrapper {
    position: absolute;
    top: 50%;
}

.gallery-slider.teaser-slider-one .teaser-slider-panel .forward-link {
    right: 5px !important;
    z-index: 2;
    text-shadow: 2px 2px #000;
    width: 120px;
    margin-left: 0;
    margin-right: 0;
}

.gallery-slider.teaser-slider-one .teaser-slider-panel .backward-link {
    left: 5px !important;
    z-index: 2;
    text-shadow: 2px 2px #000;
    width: 120px;
    margin-left: 0;
    margin-right: 0;
}

.gallery-slider.teaser-slider-one .teaser-slider-panel .close-link {
    right: 20px;
    top: 20px;
    z-index: 25;
    text-shadow: 2px 2px #000;
}

.gallery-slider.teaser-slider-one .teaser-slider-panel > a > [class^='ic'] {
    left: 50%;
}

@media @medium-devices-and-smaller {
    .quotes-slider .teaser-slider-panel {
        max-width: 80%;
    }
}

@media @small-medium-devices {
    .quotes-slider .teaser-slider-panel {
        max-width: 100%;
    }
}

@media @small-devices {
    .gallery-slider.teaser-slider-one .teaser-slider-panel .forward-link {
        right: 0!important;
        z-index: 2;
        text-shadow: 2px 2px #000;
        width: 40px;
        margin-left: 0;
        margin-right: 0;
    }
    .gallery-slider.teaser-slider-one .teaser-slider-panel .backward-link {
        left: 0!important;
        z-index: 2;
        text-shadow: 2px 2px #000;
        width: 40px;
        margin-left: 0;
        margin-right: 0;
    }
    .teaser-slider-panel>a {
        width: 20px;
    }

    .teaser-slider-list>li>a>.teaser-slider-item__heading {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .quotes-slider .teaser-slider-list > li > a {
        padding: 0;
    }

    .quotes-slider .teaser-slider-list > li > a > img {
        position: static;
        margin: 0 auto 16px;
    }

    .quotes-slider .teaser-slider-list > li > a > .teaser-slider-item__heading,
    .quotes-slider .teaser-slider-list > li > a > p {
        text-align: center;
    }

    .quote {
        left: 50%;
    }
}

.teaser-slider-noclick li>a,
.teaser-slider-one li>a{
cursor: default;
}
