.footer-nav-panel {
    display: inline-block;
    width: auto;
    margin-left: 250px;
    line-height: 26px;
    vertical-align: middle;
}

.footer-reisebuero .footer-nav-panel {
    margin-left: 160px;
}

ul.footer-nav{
    padding-top: 4px;
}

ul.footer-nav a {
    color: white;
    text-decoration: none;
    transition: background 0.3s, color 0.3s;
    padding: 2px;
    font-size: 16px;
}

ul.footer-nav a:focus,
ul.footer-nav a:hover {
    color: #fff;
    background: #676767;
}

ul.footer-nav a:active {
    color: #222;
    background: #F0F0F0;
}

ul.footer-nav li:after {
    content: '/';
    display: inline-block;
    padding-left: 8px;
    padding-right: 4px;
    font-size: 18px;
}

ul.footer-nav li:last-child:after {
    display: none;
}

@media @medium-devices-and-smaller {
    .footer-nav-panel,
    .footer-reisebuero .footer-nav-panel {
        display: block;
        position: relative;
        width: 100%;
        top: auto;
        left: auto;
        text-align: center;
          margin: 20px 0 0 0;
    }
}