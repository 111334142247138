.train-equipment {
    position: relative;
    width: 100%;
    height: 420px;
    overflow: hidden;
    z-index: 2;
    background-color: #111b42;

    // [hidden] {
    //     display: none;
    // }
}

.train-equipment--railjet {
    background-color: #ffffff;
}

.train-equipment > img {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -640px;
    width: 1280px;
    height: 400px;
    z-index: 1;
}

.train-equipment-a,
.train-equipment-b {
    display: block;
    visibility: visible;

    opacity: 1;
    transition: opacity 1s ease, visibility 1s ease;

    &[hidden] {
        // display: none;
        visibility: hidden;
        opacity: 0;
    }
}

.symbolic-image {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    color: white;
    font-size: 12px;
    line-height: 18px;
    padding: 2px 4px;
}

.train-equipment-scrollpanel {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 420px;
    z-index: 10;
    overflow: auto;
}

.train-equipment-content {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    // width: 3614px;
    height: 400px;
    -webkit-transform: translateZ(0) scale(1.0, 1.0);
    transform: translateZ(0);
}

// .train-equipment-content.animate {
//     transition-property: transform;
//     transition-duration: 3.4s;
//     transform: translate(-109px, 0);
// }

.train-equipment-content img {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: auto;
    height: auto;
}

.train-equipment .backward-link {
    top: 0;
    left: 0;
}

.train-equipment .backward-link [class^='ic'] {
    background-image: url(../images/icons/spritemap-white.svg#arrow_left-view);
}

.train-equipment .forward-link {
    top: 0;
    right: 0;
}

.train-equipment .forward-link [class^='ic'] {
    background-image: url(../images/icons/spritemap-white.svg#arrow_right-view);
}

.train-equipment>a {
    position: absolute;
    width: 125px;
    height: calc(~'100% - 20px');
    color: #fff;
    z-index: 20;

    .train-equipment--railjet & {
        color: #222222;
    }
}

@media @small-medium-devices {
    .train-equipment>a.backward-link,
    .train-equipment>a.forward-link {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 50%;
        margin-top: -20px;
    }
}

/* .touch .train-equipment>a {
   top:-99999em;
   left:-99999em;
   right:auto;
} */

.train-equipment>a.forward-link:active [class^='ic'],
.train-equipment>a.forward-link:focus [class^='ic'],
.train-equipment>a.forward-link:hover [class^='ic'] {
    background-image: url(../images/icons/spritemap-oebb.svg#arrow_right-view);
}

.train-equipment>a.backward-link:active [class^='ic'],
.train-equipment>a.backward-link:focus [class^='ic'],
.train-equipment>a.backward-link:hover [class^='ic'] {
    background-image: url(../images/icons/spritemap-oebb.svg#arrow_left-view);
}

.train-equipment>a>[class^='ic'] {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 40px;
    width: 40px;
    margin-top: -20px;
    margin-left: -20px;
    background-size: 40px 40px;
}

.train-equipment-item {
    position: absolute;
    display: block;
    top: 150px;
    left: 400px;
    height: 200px;
    width: 170px;
    z-index: 20;
    opacity: 0.0;
    transition: opacity 0.5s;
}

.train-equipment-item.show {
    opacity: 1.0;
}

.train-equipment-item-link {
    display: block;
    width: 100%;
    height: 100%;
}

.train-equipment-item .panorama-link {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 70px;
    height: 190px;
    text-align: center;
    transition: opacity 0.3s;
}

.train-equipment-item .panorama-link:hover {
    opacity: 1.0;
}

.train-equipment-item .panorama-link:after {
    content: "";
    display: block;
    position: absolute;
    background: #fff;
    left: 50%;
    margin-left: -2px;
    bottom: 6px;
    width: 2px;
    height: 100px;

    .train-equipment--railjet & {
        box-shadow: 0 0 15px #222222;
    }
}

.train-equipment-item {
    &--expanded {
        height: 240px;

        .panorama-link {
            height: 250px;

            &::after {
                height: 160px;
            }
        }
    }
}

.train-equipment-item img.icon-plus {
    position: static;
    display: inline-block;
    width: 50px;
    height: 50px;
}

.train-equipment-item img.icon-360 {
    position: static;
    display: inline-block;
    margin-top: 2px;
    width: 50px;
    height: 25px;
}

.train-equipment-item .text-link {
    color: white;
    display: block;
    position: absolute;
    left: 70px;
    top: 0;
    width: 100px;
    height: 190px;
    padding-top: 8px;
    text-align: left;
    transition: opacity 0.3s;
    cursor: default;

    &:hover {
        color: white;
        opacity: 1.0;
    }

    .train-equipment--railjet & {
        color: #222222;
        text-shadow: 0 0 7px white;
    }

    & [class^='ic'] {
        display: block;
        position: absolute;
        left: 0;
        top: 12px;
    }

    & > * {
        display: block;
        white-space: nowrap;
    }

    & > .compartment {
        font-weight: bold;
        -webkit-font-smoothing: antialiased;
    }

    & > [class^='ic-couchette'] {
        transform: scaleX(-1);
    }
}

.train-equipment-item a.text-link {
    cursor: pointer;
}

.train-equipment-item .ic-couchette,
.train-equipment-item .ic-couchette-white {
    transform: scaleX(-1);
}

.train-equipment-toggle-form {
    position: relative;
    z-index: 20;

    padding: 14px;
    margin-top: 14px;

    background: none;

    text-align: center;

    fieldset {
        margin-bottom: 0;
        font-size: 0;
    }
}

.train-equipment-toggle-form .toggle {
    input {
        width: 0;
        height: 0;
        position: absolute;

        &:checked + label {
            color: #ffffff;
            background: #111b42;

            .ic-icon {
                display: inline-block;
            }
        }

        &:focus {
            + label {
                color: #fff;
                cursor: pointer;
                background: #111b42;

                .is-tabbing & {
                    outline: 2px dotted @tint;
                    outline-offset: 2px;
                    z-index: 10;
                }
            }
        }
    }

    label {
        color: #111b42;
        padding: 12px;
        background-color: #ffffff;
        font-size: 16px;
        position: relative;
        overflow: hidden;
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
        transition: all .25s ease;


        &:hover {
            color: #fff;
            cursor: pointer;
            background: #111b42;
        }

        &:first-of-type {
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
        }

        &:last-of-type {
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;
        }
    }

    span {
        position: relative;
    }

    .ic-icon {
        display: none;
    }

}
