/* Always set the map height explicitly to define the size of the div element that contains the map. */

#station-connections-map {
    height: 0;
    z-index: 1;
    overflow: hidden;
}

.poi-map-wrapper {
    background-color: #ffffff;
    margin-bottom: 20px;
}

.poi-map {
    position: relative;
    z-index: 1;

    width: 100%;
    height: 400px;
    margin-bottom: -20px;
    background-color: #f5f5f5;
}

.route-maps-wrapper {
    position: relative;
    z-index: 1;

    display: block;
    padding: 25px 15px 0 15px;
}

.route-maps {
    width: 100%;
    height: 300px;
}

@media @medium-devices-and-smaller {
    .route-maps-wrapper {
        padding-top: 15px;
    }
}

.row .poi-map-wrapper,
.row .poi-map-wrapper .indented-module {
    background: none;
}

.row .poi-map-wrapper .indented-module {
    padding: 0; /* Remove padding from class `intended-module` if inside a column module */
}

.row .poi-map-wrapper .poi-map {
    height: 300px;
    margin: 0; /* Remove negative margin from class `poi-map` if inside a column module */
}
