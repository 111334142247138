 // Waterfall-Pages
.template--waterfall {
    .waterfall-header header {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        top: 0;

        &:after {
            content: none;
        }
    }

    .waterfall-header {
        position: fixed;
        width: 100%;
        z-index: 9;
        background: white;
        transition: transform .1s ease-out, margin-left .4s linear;

        .ocopen& {
            &:extend(.ocopen header all);
        }
        
        @media @screen-lg-min {
            .header-is-sticky& {
                transform: translateY(-75px);
            }
        }
    }

    body {
        padding-bottom: 0;
    }

    footer {
        position: relative;
        z-index: 9;
    }

    .indented-module.white,
    .indented-module .white,
    .indented-module.pebble-gray,
    .indented-module .pebble-gray {
        background: transparent;
    }


    // Overriding
    .content .biggest {
        margin-bottom: 0;
    }

    .content .bigger {
        font-size: 40px;
        font-weight: bold;
        -webkit-font-smoothing: antialiased;
    }

    .content .big {
        font-size: 26.8px;
    }
}

.waterfall__sequenz {
    min-height: ~'calc(100vh - 75px)';
    position: relative;
    z-index: 1;

    @media @screen-sm-max {
        &--video {
            min-height: 0;
        }
    }
}

.waterfall__background {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
    transform: translate(-50%,-50%);

    .edit-mode & {
        opacity: 1;
        height: 300px;
        position: static;
        transform: translate(0,0);
    }

    &--color-transparent { background: transparent; }
    &--color-red { background: #E2002A; color: white!important;}
    &--color-white { background: white; }
    &--color-black { background: #222528; color: white!important; }

    &--image {
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
    }

    &--video {
        width: 125%;
        height: 125%;

        @media @screen-sm-max {
            position: static;
            pointer-events: unset;
            transform: translate(0,0);
            height: 100vh;
            width: 100%;
        }
    }
}

.waterfall__background-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media (min-aspect-ratio: ~'16/9') {
        .video-foreground { height: 300%; top: -100%; }
    }
    @media (max-aspect-ratio: ~'16/9') {
        .video-foreground { width: 300%; left: -100%; }
    }
}

.waterfall__content {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--red { background: #E2002A; color: white!important;}
    &--white { background: white; }
    &--black { background: #222528; color: white!important; }

    &--white-font-color,
    &--red,
    &--black {
        color: white!important;

        > * {
            color: inherit!important;
        }
    }

    .container {
        background: transparent;
    }

    @media @screen-sm-max {
        .waterfall__sequenz--video & {
            min-height: 0;
        }
    }
}

#waterfall-youtube-disclaimer {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 10px 10px 0;
    background: white;
    z-index: 999;
    transition: max-width .35s ease;
    box-shadow: 4px 3px 7px 0px black;

    &.open {
        max-width: 300px;
    }

    &.closed {
        display: none;
    }
}
#waterfall-youtube-disclaimer__txt {
    max-height: 0;
    max-width: 0;
    transition: max-height .35s ease;
    overflow: hidden;

    #waterfall-youtube-disclaimer.open & {
        max-height: 700px;
        max-width: 300px;
    }
}

button#waterfall-youtube-disclaimer__btn{
    font-size:16px;
    min-width:unset;
    min-height:unset;
}

button#waterfall-youtube-disclaimer__more-btn {
    display: block;
    margin: 0 0 20px;
    background-color: transparent;
	color: @tint;
	text-decoration: underline;
	border: none;
	padding: 0;
    cursor:pointer;
}

button#waterfall-youtube-disclaimer__close-btn {
    background: transparent;
    border: none;
    box-shadow: none;
    cursor: pointer;
    margin-bottom:0.5rem;
}

.hide-accessible {
	display: block;
	position: absolute!important;
	left: -9999em!important;
}
