
/* Downloads-Tabelle */
.content .downloads ul{
    list-style-type: none;
    margin-bottom: 0;
}
.content .downloads li {
  margin: 0;
    padding: 0;
}
.content .downloads li::before {
    display: none;
}

.content .downloads li a{
    display: block;
    width: 100%;
    padding-left: 20px;
    text-decoration: none;
    border-bottom: 1px solid #E4E4E4;
    padding-bottom: 8px;
    padding-top: 8px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    background-size: 12px 12px;
      background: url(../images/icons/spritemap-oebb.svg#download-view) no-repeat left center;
}
    .content .downloads li a:focus,
       .content .downloads li a:hover
    {
             background: url(../images/icons/spritemap-darkgray.svg#download-view) no-repeat left center;
         
    }
    .content .downloads span{  
    text-align: right;
    float: right;
    color: #666666;
}

.col-8~.col-4>.vertical-scroll>.downloads{
    margin-top: 50px;
}
@media @medium-devices-and-smaller {

.col-8~.col-4>.vertical-scroll>.downloads{
    margin-top: 0;
}
.col-8~.col-4>.link-list{
    margin-top: 0;
}
}

@media @small-devices {
   .content .downloads span{
        display:block;
        float: none;
        width:100%;
    text-align: left;
   }
    .content .downloads span::before{
        content:"("
    }
     .content .downloads span::after{
        content:")"
    }
}
