.image-credits {
	color: #666666;

	h2 {
		font-size: 18px;
	}

	ol,
	ul {
		margin: 0;
		padding: 0;

		list-style: none;

		font-size: 15px;

		li {
			display: inline;
		}
	}
}
