span[class^='ic'],
i[class^='ic'] {
  display: inline-block;
  line-height: 20px;
  width: 20px;
  height: 20px;
  background-size: 18px 18px;
  vertical-align: top;
  background-repeat: no-repeat;
  background-position: bottom center;
}

span.ic-xs,
i.ic-xs {
  font-size: 14px;
  line-height: 10px;
  width: auto;
  height: 14px;
}

span.ic-s,
i.ic-s {
  background-size: 16px 16px;
  height: 16px;
  width: 16px;
  vertical-align: text-bottom;
}

span.ic-fs16,
i.ic-fs16 {
  background-size: 16px 16px;
  height: 16px;
  width: 16px;
  vertical-align: middle;
}

span.ic-fs20,
i.ic-fs20 {
  background-size: 20px;
  height: 20px;
  width: 20px;
  vertical-align: middle;
}

span.ic-default,
i.ic-default {
  font-size: 28px;
  line-height: 20px;
  width: 20px;
  height: 20px;
}

span.ic-weather,
i.ic-weather {
  background-size: 36px 36px;
  vertical-align: middle;
  width: 36px;
  height: 34px;
}

span.ic-l,
i.ic-l {
  font-size: 50px;
  line-height: 42px;
  width: auto;
  height: 42px;
}

span.ic-xl,
i.ic-xl {
  font-size: 72px;
  line-height: 64px;
  width: auto;
  height: 64px;
}

.ic-nav-expand {
  background-image: url(../images/icons/spritemap-black.svg#arrow_down-view);
}

.ic-arrow-down {
  background-image: url(../images/icons/spritemap-black.svg#arrow_down-view);
}

.ic-arrow-down-alt {
  background-image: url(../images/loyalty-program/arrow-down-alt.svg);
}

.ic-arrow-up-alt {
  background-image: url(../images/loyalty-program/arrow-up-alt.svg);
}

.ic-arrow-up-down-alt {
  background-image: url(../images/loyalty-program/arrow-up-down-alt.svg);
}
.ic-arrow-up {
  background-image: url(../images/icons/spritemap-black.svg#arrow_up-view);
}

.ic-arrow-down--red {
  background-image: url(../images/icons/spritemap-oebb.svg#arrow_down-view);
}
.ic-arrow-down--darkgray {
  background-image: url(../images/icons/spritemap-darkgray.svg#arrow_down-view);
}

a:active .ic-nav-expand,
a:focus .ic-nav-expand,
a:hover .ic-nav-expand {
  background-image: url(../images/icons/spritemap-oebb.svg#arrow_down-view);
}

.main-nav>li>a>span.ic-nav-expand {
  width: 25px;
  height: 25px;
}

.main-nav .open .ic-nav-expand {
  background-image: url(../images/icons/spritemap-black.svg#arrow_up-view);
}

.connectionsList .ic-nav-expand {
  width: 30px;
  height: 24px;
  background-image: url(../images/icons/spritemap-oebb.svg#arrow_down-view);
}

.connectionsList a:active .ic-nav-expand,
.connectionsList a:focus .ic-nav-expand,
.connectionsList a:hover .ic-nav-expand {
  background-image: url(../images/icons/spritemap-black.svg#arrow_down-view);
}

.connectionsList .expandable-open a .ic-nav-expand .connectionsList .expandable-open a:active .ic-nav-expand,
.connectionsList .expandable-open a:focus .ic-nav-expand,
.connectionsList .expandable-open a:hover .ic-nav-expand {
  background-image: url(../images/icons/spritemap-black.svg#arrow_up-view);
}

.ic-close {
  background-image: url(../images/icons/spritemap-black.svg#close-view);
}

.ic-close-lp {
  background-image: url(../images/loyalty-program/close.svg);
}

.ic-close-white {
  background-image: url(../images/icons/spritemap-white.svg#close-view);
}

a:active .ic-close,
a:focus .ic-close,
a:hover .ic-close {
  background-image: url(../images/icons/spritemap-oebb.svg#close-view);
}

.ic-tickets {
  vertical-align: text-bottom;
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 0;
  background-image: url(../images/icons/spritemap-black.svg#ticketshop-view);
}

.more-btn .ic-tickets,
.action-btn .ic-tickets,
.service-nav .ic-tickets {
  background-image: url(../images/icons/spritemap-white.svg#ticketshop-view);
}

a:active .ic-tickets,
a:focus .ic-tickets,
a:hover .ic-tickets {
  background-image: url(../images/icons/spritemap-oebb.svg#ticketshop-view);
}

.ic-user {
  vertical-align: text-bottom;
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 0;
  background-image: url(../images/icons/spritemap-lightgray.svg#user-view);
}

a:active .ic-user,
a:focus .ic-user,
a:hover .ic-user {
  background-image: url(../images/icons/spritemap-oebb.svg#user-view);
}

.ic-login-white {
  vertical-align: text-bottom;
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 0;
  background-image: url(../images/icons/spritemap-white.svg#login-view);
}

a:active .ic-login-white,
a:focus .ic-login-white,
a:hover .ic-login-white {
  background-image: url(../images/icons/spritemap-oebb.svg#login-view);
}

.ic-external-link {
  background-image: url(../images/icons/spritemap-lightgray.svg#linkextern-view);
}

a:active .ic-external-link,
a:focus .ic-external-link,
a:hover .ic-external-link {
  background-image: url(../images/icons/spritemap-oebb.svg#linkextern-view);
}

.ic-faq {
  background-image: url(../images/icons/spritemap-lightgray.svg#question-view);
}

a:active .ic-faq,
a:focus .ic-faq,
a:hover .ic-faq {
  background-image: url(../images/icons/spritemap-oebb.svg#question-view);
}

.ic-cartrain {
  background-image: url(../images/icons/spritemap-darkgreen.svg#transport-view);
}

.not-possible span.ic-cartrain,
span.ic-cartrain.not-possible,
.not-possible i.ic-cartrain,
i.ic-cartrain.not-possible {
  background-image: url(../images/icons/spritemap-darkgray.svg#transport-view);
}

.more-btn-gray span.ic-cartrain,
.more-btn-gray i.ic-cartrain {
  background-image: url(../images/icons/spritemap-white.svg#transport-view);
  background-size: 30px 20px;

}

.content .connections a.more-btn-gray span.ic-cartrain,
.content .connections a.more-btn-gray i.ic-cartrain {
  margin-top: 0;
  margin-left: -1px;
  margin-right: 3px;
}

a.more-btn-gray:active span.ic-cartrain,
a.more-btn-gray:focus span.ic-cartrain,
a.more-btn-gray:hover span.ic-cartrain,
a.more-btn-gray:active i.ic-cartrain,
a.more-btn-gray:focus i.ic-cartrain,
a.more-btn-gray:hover i.ic-cartrain {
  background-image: url(../images/icons/spritemap-darkgray.svg#transport-view);
}

.ic-search {
  background-image: url(../images/icons/spritemap-lightgray.svg#search-view);
}

a:active .ic-search,
a:focus .ic-search,
a:hover .ic-search {
  background-image: url(../images/icons/spritemap-oebb.svg#search-view);
}

.ic-contact {
  background-image: url(../images/icons/spritemap-lightgray.svg#phone-view);
}

a:active .ic-contact,
a:focus .ic-contact,
a:hover .ic-contact {
  background-image: url(../images/icons/spritemap-oebb.svg#phone-view);
}

.ic-contact-mail {
  background-image: url(../images/icons/spritemap-lightgray.svg#mail-view);
}

a:active .ic-contact-mail,
a:focus .ic-contact-mail,
a:hover .ic-contact-mail {
  background-image: url(../images/icons/spritemap-oebb.svg#mail-view);
}

.ic-niceprice {
  vertical-align: text-bottom;
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 0;
  background: url(../images/icons/spritemap-black.svg#niceprice-view) no-repeat left center;
}

a:active .ic-niceprice,
a:focus .ic-niceprice,
a:hover .ic-niceprice {
  background-image: url(../images/icons/spritemap-oebb.svg#niceprice-view)
}

.ic-newspaper {
  vertical-align: text-bottom;
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 0;
  background: url(../images/icons/spritemap-black.svg#newspaper-view) no-repeat left center;
}

a:active .ic-newspaper,
a:focus .ic-newspaper,
a:hover .ic-newspaper {
  background-image: url(../images/icons/spritemap-oebb.svg#newspaper-view)
}

.ic-warning {
  vertical-align: text-bottom;
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 0;
  background: url(../images/icons/spritemap-black.svg#warning-view) no-repeat left center;
}

.ic-warning-orange {
  vertical-align: text-bottom;
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 0;
  background: url(../images/icons/spritemap-orange.svg#warning-view) no-repeat left center;
}

a:active .ic-warning,
a:focus .ic-warning,
a:hover .ic-warning,
a:active .ic-warning-orange,
a:focus .ic-warning-orange,
a:hover .ic-warning-orange {
  background-image: url(../images/icons/spritemap-oebb.svg#warning-view)
}

.ic-route {
  vertical-align: text-bottom;
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 0;
  background: url(../images/icons/spritemap-black.svg#route-view) no-repeat left center;
}

a:active .ic-route,
a:focus .ic-route,
a:hover .ic-route {
  background-image: url(../images/icons/spritemap-oebb.svg#route-view)
}

.ic-forward,
.ic-nav-forward {
  background-image: url(../images/icons/spritemap-black.svg#arrow_right-view);
}

.ic-arrow-right--lp {
  background-image: url(../images/icons/spritemap-lp.svg#arrow_right-view);
}

.ic-menu-item-forward {
  background-image: url(../images/icons/spritemap-white.svg#arrow_right-view);
}

.newsletter-btn .ic-nav-forward,
.teaser-slider-equipment .ic-nav-forward,
.ticketshop-link .ic-nav-forward,
.teaser-slider-one:focus .ic-nav-forward,
.teaser-slider-one:hover .ic-nav-forward {
  background-image: url(../images/icons/spritemap-white.svg#arrow_right-view);
}

.newsletter-btn:focus .ic-nav-forward,
.newsletter-btn:hover .ic-nav-forward {
  background-image: url(../images/icons/spritemap-black.svg#arrow_right-view);
}

// @media @big-devices-and-smaller {
//     ul.main-nav ul>li>a i{
//         width: 48px;
//         background-image: url(../images/icons/spritemap-lightgray.svg#linkextern-view);
//     }
// }

span.ic-forward-red,
.ic-forward-red {
  background-image: url(../images/icons/spritemap-oebb.svg#arrow_right-view);
  background-size: 12px 12px;
  background-position: 0 3px;

  a:hover &,
  a:focus &,
  a:active & {
    background-image: url(../images/icons/spritemap-black.svg#arrow_right-view)
  }
}


a:active .ic-forward,
a:focus .ic-forward,
a:hover .ic-forward,
a:active .ic-nav-forward,
a:focus .ic-nav-forward,
a:hover .ic-nav-forward,
.action-btn:active .ic-nav-forward,
.action-btn:focus .ic-nav-forward,
.action-btn:hover .ic-nav-forward,
.more-btn:active .ic-nav-forward,
.more-btn:focus .ic-nav-forward,
.more-btn:hover .ic-nav-forward {
  background-image: url(../images/icons/spritemap-oebb.svg#arrow_right-view)
}

.action-btn .ic-nav-forward,
.more-btn .ic-nav-forward {
  background-image: url(../images/icons/spritemap-white.svg#arrow_right-view)
}

.more-btn-gray .ic-nav-forward {
  background-image: url(../images/icons/spritemap-white.svg#arrow_right-view)
}

a.more-btn-gray:active .ic-nav-forward,
a.more-btn-gray:focus .ic-nav-forward,
a.more-btn-gray:hover .ic-nav-forward {
  background-image: url(../images/icons/spritemap-darkgray.svg#arrow_right-view)
}

.ic-nav-backward {
  background-image: url(../images/icons/spritemap-black.svg#arrow_left-view);
}

#main-menu .ic-nav-backward {
  background-image: url(../images/icons/spritemap-white.svg#arrow_left_alt-view)
}

.teaser-slider-equipment .ic-nav-backward,
.teaser-slider-one .ic-nav-backward,
.teaser-slider-one .ic-nav-backward {
  background-image: url(../images/icons/spritemap-white.svg#arrow_left-view);
}

a:active .ic-nav-backward,
a:focus .ic-nav-backward,
a:hover .ic-nav-backward {
  background-image: url(../images/icons/spritemap-oebb.svg#arrow_left-view)
}

.ic-transport {
  vertical-align: text-bottom;
  content: " ";
  width: 43px;
  height: 35px;
  margin: -2px 0 0 0;
  background: url(../images/icons/spritemap-darkgray.svg#transport-view) no-repeat left center;
}

a:active .ic-transport,
a:focus .ic-transport,
a:hover .ic-transport {
  background-image: url(../images/icons/spritemap-oebb.svg#transport-view)
}

.action-btn .ic-transport {
  height: 25px;
  vertical-align: middle;
  background-image: url(../images/icons/spritemap-white.svg#transport-view);
  background-size: 32px;
  background-position: center;
}

.connections li span.ic-bike,
.connections li i.ic-bike {
  vertical-align: middle;
  content: " ";
  width: 22px;
  height: 22px;
  margin: -2px 2px 0 0;
  background: url(../images/icons/spritemap-black.svg#bike-view) no-repeat left center;
  background-size: 22px 22px
}

.ic-wheelchair {
  vertical-align: text-bottom;
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 0;
  background: url(../images/icons/spritemap-black.svg#wheelchair-view) no-repeat left center;
}

a:active .ic-wheelchair,
a:focus .ic-wheelchair,
a:hover .ic-wheelchair {
  background-image: url(../images/icons/spritemap-oebb.svg#wheelchair-view)
}

.connections li span.ic-wheelchair,
.connections li i.ic-wheelchair {
  vertical-align: middle;
  content: " ";
  width: 22px;
  height: 22px;
  margin: -2px 2px 0 0;
  background-size: 20px 20px
}

.ic-car {
  vertical-align: middle;
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 2px 0 0;
  background: url(../images/icons/spritemap-black.svg#car-view) no-repeat left center;
  background-size: 24px 24px
}

a:active .ic-car,
a:focus .ic-car,
a:hover .ic-car {
  background-image: url(../images/icons/spritemap-oebb.svg#car-view)
}

.ic-motorcycle {
  content: "  ";
  vertical-align: middle;
  width: 22px;
  height: 14px;
  margin: -2px 02px 0 0;
  background: url(../images/icons/spritemap-black.svg#motorcycle-view) no-repeat left center;
  background-size: 24px 24px
}

.ic-seat {
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 4px;
  background: url(../images/icons/spritemap-black.svg#class1-view) no-repeat left center;
}

.ic-seat-white {
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 4px;
  background: url(../images/icons/spritemap-white.svg#class1-view) no-repeat left center;
}

.ic-couchette {
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 4px;
  background: url(../images/icons/spritemap-black.svg#class2-view) no-repeat left center;
}

.ic-couchette-white {
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 4px;
  background: url(../images/icons/spritemap-white.svg#class2-view) no-repeat left center;
}

.ic-sleeper {
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 4px;
  background: url(../images/icons/spritemap-black.svg#class3-view) no-repeat left center;
}

.ic-sleeper-white {
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 4px;
  background: url(../images/icons/spritemap-white.svg#class3-view) no-repeat left center;
}

.ic-map {
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 4px;
  background: url(../images/icons/spritemap-black.svg#map-view) no-repeat left center;
}

a:active .ic-map,
a:focus .ic-map,
a:hover .ic-map {
  background-image: url(../images/icons/spritemap-oebb.svg#map-view);
}

.service-nav .ic-map {
  background-image: url(../images/icons/spritemap-lightgray.svg#map-view);
}

.service-nav .ticketshop-link .ic-map {
  background-image: url(../images/icons/spritemap-white.svg#map-view);
}

.service-nav .ticketshop-link:active .ic-map,
.service-nav .ticketshop-link:focus .ic-map,
.service-nav .ticketshop-link:hover .ic-map {
  background-image: url(../images/icons/spritemap-oebb.svg#map-view);
}

.ic-luggage {
  vertical-align: text-bottom;
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 0;
  background: url(../images/icons/spritemap-black.svg#luggage-view) no-repeat left center;
}

a:active .ic-luggage,
a:focus .ic-luggage,
a:hover .ic-luggage {
  background-image: url(../images/icons/spritemap-oebb.svg#luggage-view)
}

.action-btn .ic-luggage,
.service-nav .ic-luggage {
  background-image: url(../images/icons/spritemap-white.svg#luggage-view);
}

.ic-play {
  background-image: url(../images/icons/spritemap-black.svg#play-view);
}

.ic-play-white {
  background-image: url(../images/icons/spritemap-white.svg#play-view);
}

.ic-pause {
  background-image: url(../images/icons/spritemap-black.svg#pause-view);
}

.ic-pause-white {
  background-image: url(../images/icons/spritemap-white.svg#pause-view);
}

.ic-bed {
  vertical-align: text-bottom;
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 0;
  background: url(../images/icons/spritemap-black.svg#bed-view) no-repeat left center;
}

a:active .ic-bed,
a:focus .ic-bed,
a:hover .ic-bed {
  background-image: url(../images/icons/spritemap-oebb.svg#bed-view)
}

.ic-bike {
  vertical-align: text-bottom;
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 0;
  background: url(../images/icons/spritemap-black.svg#bike-view) no-repeat left center;
}

a:active .ic-bike,
a:focus .ic-bike,
a:hover .ic-bike {
  background-image: url(../images/icons/spritemap-oebb.svg#bike-view)
}

.ic-day {
  vertical-align: text-bottom;
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 0;
  background: url(../images/icons/spritemap-black.svg#day-view) no-repeat left center;
}

a:active .ic-day,
a:focus .ic-day,
a:hover .ic-day {
  background-image: url(../images/icons/spritemap-oebb.svg#day-view)
}

.ic-destination {
  vertical-align: text-bottom;
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 0;
  background: url(../images/icons/spritemap-black.svg#destination-view) no-repeat left center;
}

a:active .ic-destination,
a:focus .ic-destination,
a:hover .ic-destination {
  background-image: url(../images/icons/spritemap-oebb.svg#destination-view)
}

.ic-info {
  vertical-align: text-bottom;
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 0;
  background: url(../images/icons/spritemap-black.svg#info-view) no-repeat left center;
}

.ic-info-gray {
  vertical-align: text-bottom;
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 0;
  background: url(../images/icons/spritemap-lightgray.svg#info-view) no-repeat left center;
}

.ic-info.ic-info--white {
  background: url(../images/icons/spritemap-white.svg#info-view) no-repeat left center;

  :hover &,
  :active &,
  :focus & {
    background: url(../images/icons/spritemap-white.svg#info-view) no-repeat left center;
  }
}

.ic-info.ic-info--darkgray {
  background: url(../images/icons/spritemap-darkgray.svg#info-view) no-repeat left center;

  :hover &,
  :active &,
  :focus & {
    background: url(../images/icons/spritemap-darkgray.svg#info-view) no-repeat left center;
  }
}

a:active .ic-info,
a:focus .ic-info,
a:hover .ic-info,
.active .ic-info,
a:active .ic-info-gray,
a:focus .ic-info-gray,
a:hover .ic-info-gray,
.active .ic-info-gray {
  background-image: url(../images/icons/spritemap-oebb.svg#info-view)
}

.ic-settings {
  vertical-align: text-bottom;
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 0;
  background: url(../images/icons/spritemap-black.svg#settings-view) no-repeat left center;
}

.ic-settings-gray {
  vertical-align: text-bottom;
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 0;
  background: url(../images/icons/spritemap-lightgray.svg#settings-view) no-repeat left center;
}

a:active .ic-settings,
a:focus .ic-settings,
a:hover .ic-settings,
.active .ic-settings,
a:active .ic-settings-gray,
a:focus .ic-settings-gray,
a:hover .ic-settings-gray,
.active .ic-settings-gray {
  background-image: url(../images/icons/spritemap-oebb.svg#settings-view);
}

.ic-contrast {
  vertical-align: text-bottom;
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 0;
  background: url(../images/icons/spritemap-black.svg#contrast-view) no-repeat left center;
}

.ic-contrast-gray {
  vertical-align: text-bottom;
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 0;
  background: url(../images/icons/spritemap-lightgray.svg#contrast-view) no-repeat left center;
}

a:active .ic-contrast,
a:focus .ic-contrast,
a:hover .ic-contrast,
.active .ic-contrast,
a:active .ic-contrast-gray,
a:focus .ic-contrast-gray,
a:hover .ic-contrast-gray,
.active .ic-contrast-gray {
  background-image: url(../images/icons/spritemap-oebb.svg#contrast-view);
}

.ic-location {
  vertical-align: text-bottom;
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 0;
  background: url(../images/icons/spritemap-black.svg#location-view) no-repeat left center;
}

a:active .ic-location,
a:focus .ic-location,
a:hover .ic-location {
  background-image: url(../images/icons/spritemap-oebb.svg#location-view)
}

.ic-mail {
  vertical-align: text-bottom;
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 0;
  background: url(../images/icons/spritemap-black.svg#mail-view) no-repeat left center;
}

a:active .ic-mail,
a:focus .ic-mail,
a:hover .ic-mail {
  background-image: url(../images/icons/spritemap-oebb.svg#mail-view)
}

.ic-mail-lighter {
  background: url(../images/icons/spritemap-darkgray.svg#mail-view) no-repeat left center;
}

.ic-message {
  vertical-align: text-bottom;
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 0;
  background: url(../images/icons/spritemap-black.svg#message-view) no-repeat left center;
}

a:active .ic-message,
a:focus .ic-message,
a:hover .ic-message {
  background-image: url(../images/icons/spritemap-oebb.svg#message-view)
}

.ic-payment {
  vertical-align: text-bottom;
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 0;
  background: url(../images/icons/spritemap-black.svg#payment-view) no-repeat left center;

  a:active &,
  a:focus &,
  a:hover & {
    background-image: url(../images/icons/spritemap-oebb.svg#payment-view)
  }
}

.ic-payment-white {
  .ic-payment();

  background-image: url(../images/icons/spritemap-white.svg#payment-view);

  a:active &,
  a:focus &,
  a:hover & {
    background-image: url(../images/icons/spritemap-oebb.svg#payment-view);
  }
}

.ic-download {
  vertical-align: text-bottom;
  content: " ";
  width: 22px;
  height: 14px;
  margin: -2px 0 0 0;
  background: url(../images/icons/spritemap-darkgray.svg#download-view) no-repeat left center;
}

.ic-chatbot-white,
span.ic-chatbot-white,
i.ic-chatbot-white {
  width: 25px;
  height: 25px;
  background-image: url(../images/icons/spritemap-white.svg#chatbot-oebb-view);
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 25px;
}

.ic-chatbot-red,
span.ic-chatbot-red,
i.ic-chatbot-red {
  width: 25px;
  height: 25px;
  background-image: url(../images/icons/spritemap-oebb.svg#chatbot-oebb-view);
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 25px;
}

.ic-check {
  background-image: url(../images/icons/spritemap-black.svg#check-view);
}

.ic-check-white {
  background-image: url(../images/icons/spritemap-white.svg#check-view);
}

.ic-lock {
  background-image: url(../images/icons/spritemap-black.svg#lock-view);
}

.ic-loyalty-program {
  background-image: url('../images/loyalty-program/icon-layout-bonuspunkt.svg');
  background-position: left center;
  background-repeat: no-repeat;
}

.ic-loyalty-program--white {
  background-image: url('../images/loyalty-program/icon-layout-bonuspunkt-white.svg');

  a:focus &,
  a:hover &,
  a:active & {
    background-image: url('../images/loyalty-program/icon-layout-bonuspunkt-red.svg');
  }
}

.ic-loyalty-logout {
  background-image: url('../images/loyalty-program/icon-logout-white.svg');

  button:focus &,
  button:hover &,
  button:active & {
    background-image: url('../images/loyalty-program/icon-logout-red.svg');
  }
}+

.ic-arrow-up--red{

}