.expandables ul {
    list-style: none;
}

/* Remove padding from class `intended-module` if inside a column module */
.row .expandables {
    padding: 0;
}

.expandable {
    // margin-bottom: 4px;
    border-bottom: 1px solid #E4E4E4;

    .no-last-bottom-line &:last-child {
        border-bottom: none;
    }
}

.expandables ul li.expandable {
    margin: 0;
    padding: 0;

    scroll-margin-top: 100px;
}

.expandables ul li.expandable::before {
    content: none;
}

.expandable .expandable__header {
    display: block;
    width: 100%;
    padding: 12px;

    cursor: pointer;

    color: #222222;
    font-weight: 700;
    text-decoration: none;
    // white-space: nowrap;
}

// Bring focusring to foreground
.expandable .expandable__header:focus {
    position: relative;
    z-index: 1;
}

.expandable__header .expandable__icon {
    background-image: url(../images/icons/spritemap-black.svg#arrow_down-view);
}

.expandable__header:focus .expandable__icon,
.expandable__header:hover .expandable__icon,
.expandable__header:active .expandable__icon {
    background-image: url(../images/icons/spritemap-black.svg#arrow_down-view);
}

.expandable.expandable-open .expandable__icon {
    background-image: url(../images/icons/spritemap-black.svg#arrow_up-view);
}

.expandable__title {
    display: inline-block;
    vertical-align: middle;
    width: ~'calc(100% - 26px)'; // Substract icon width
    padding: 0 6px;
    // overflow: hidden;

    // text-overflow: ellipsis;
}

.expandable__body {
    padding: 12px 12px 12px 42px;
}

.expandable .expandable-traffic-rss-download {
    color: #666666;
    text-decoration: none;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
}

.expandable .expandable-traffic-rss-download:hover {
    color: #000000;
}
