.from-to {
    display: block;
    width: 100%;
}

.from-to-form {
    display: block;
    padding: 1rem 1rem 0;

    // background-color: #ffffff;
}

.from-to-form--right {
    position: absolute;
    top: -90px;
    right: 0;
    z-index: 10;

    display: block;
    width: 100%;
    max-width: 570px;
    margin: 0 auto;
}

.from-to .screen-reader-text-jump {
    position: absolute;
    right: 6px;
    top: -130px;

    padding: 8px 4px;
}

.from-to .screen-reader-text-jump:focus {
    background-color: #ffffff;
}

@media @medium-devices-and-smaller {
    .from-to-form--right {
        top: -20px;

        max-width: 480px;
    }

    .from-to .screen-reader-text-jump {
        top: -50px;
    }
}

@media @small-medium-devices {
    .from-to-form--right {
        position: relative;
        top: auto;
        right: auto;

        width: 100%;
        max-width: none;
    }
}
